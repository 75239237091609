import React from "react";

function Support() {

    return (
        <div className="w-100 px-4">
            <h5 className="mb-3 mt-5">Write Message</h5>
            <div className="mb-3">
                <textarea className="w-100 custom-input custom-shadow" style={{ height: '250px' }} />
            </div>
            <button className="w-100 rounded-5 p-2 fs-4 mt-5 button">Send Message</button>
        </div>
    )
}

export default Support;