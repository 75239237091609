import React, { useContext } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Outlet, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AlertContext from "../Contexts/AlertContext";
import Setting from "./Setting";

function MainScreen() {

    const navigate = useNavigate();
    const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);

    return (
        <div className="w-100 h-100" style={{position: "relative", display: "flex", flexDirection: "column"}}>
            <div className="d-flex w-100 py-2 px-3 border-bottom">
                <div className="d-flex justify-content-start">
                    <ChevronLeftOutlinedIcon sx={{fontSize: "30px"}} className="color me-2" onClick={() => {window.history.back()}} />
                    <MenuIcon sx={{fontSize: "30px"}} className="color" onClick={() => {setMenu(!menu)}} />
                </div>
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <h5 className="card-title text-center">Ebidir Plus</h5>
                </div>
                <div className="d-flex justify-content-end">
                    <CalculateOutlinedIcon sx={{fontSize: "30px"}} className="color" />
                </div>
            </div>
            <div className="w-100" style={{position: "relative", overflow: "hidden auto", height: "100%"}}>
                <div className="w-100 h-100" style={{overflow: "hidden auto"}}>
                    <Outlet />
                    {/* display: menu ? "none" : "",  */}
                </div>
                    {menu ? (
                        <div className="w-100 h-100 bg-white" style={{overflow: "hidden auto", position: "absolute", top: 0, zIndex: 1000}}>
                            <Setting />
                        </div>
                    ) : ""}
            </div>
            <div className="d-flex w-100 py-2 border-top">
                <div className="w-100 d-flex justify-content-center">
                    <HomeOutlinedIcon sx={{fontSize: "40px"}} className="color" onClick={() => {navigate("/")}} />
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <AddIcon sx={{fontSize: "40px"}} className="color" onClick={() => {navigate("/select_bank")}} />
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <AccountCircleOutlinedIcon sx={{fontSize: "40px"}} className="color"  onClick={() => {navigate("/profile")}}/>
                </div>
            </div>
        </div>
    );
}

export default MainScreen;