import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import AlertContext from "../Contexts/AlertContext";
import { useNavigate } from "react-router-dom";
import { Login } from "../APIs/AuthAPI";
import AuthContext from "../Contexts/AuthContext";
import LockOpenIcon from '@mui/icons-material/LockOpen';

function LoginPage() {

  const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
  const { setLoggedUser, setLoggedIn, setCookie, signIn } = useContext(AuthContext);

  const [fields, setFields] = useState<{ Phone: string, Password: string }>({
    Phone: "",
    Password: ""
  });

  const style = {
    mobile: {
      width: "100%",
      // height: "100%"
    },
    desktop: {
      width: "35%"
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const fieldSetter = (type: ("Phone" | "Password"), value: any) => {
    setFields({ ...fields, [type]: value });
  }

  const submitForm = async (event: any) => {
    event.preventDefault();

    setTimeout(() => { setWaiting(true) }, 1);
    try {
      let response = await Login(fields.Phone, fields.Password);

      // setLoggedUser(response);
      // setCookie("login_token", response.Token, { path: "/" });
      // setLoggedIn(true);

      // setAlert("working", "info");
      await signIn(response);
      setWaiting(false);
      navigate("/");

    } catch (error: any) {
      setWaiting(false);
      setAlert(error.message, "error");
    }


  }

  return (
    <section className="h-100 gradient-form" style={{ backgroundColor: "#eee" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="col-lg-6">
                  <div className="card-body p-md-5 mx-md-4">

                    <div className="text-center">
                      <img src='images/EBIDIR-LOGO.png'
                        style={{ width: "185px" }} alt="logo"></img>
                    </div>

                    <form>
                      <h4 className="text-center">Login</h4>

                      <div data-mdb-input-init className="form-outline mb-4">
                        <label className="form-label" >Phone</label>
                          <input type="text" className="form-control" required value={fields.Phone} onChange={(event: any) => { fieldSetter("Phone", event.target.value) }} id="phone_input" placeholder="+251-"
                        />

                      </div>

                      <div data-mdb-input-init className="form-outline mb-4">
                        <label className="form-label" >Password</label>
                        <input type="password" className="form-control" id="password_input" placeholder="Enter Your Password" required value={fields.Password} onChange={(event: any) => { fieldSetter("Password", event.target.value) }} />

                      </div>

                      <div className="text-center pt-1 mb-2 pb-1">
                        <button onClick={(e) => { submitForm(e) }} data-mdb-button-init data-mdb-ripple-init className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button"><LockOpenIcon sx={{ fontSize: 25, marginRight: "10px" }} />
                          Sign In</button>

                      </div>
                      <div className="text-center">

                        <button className="btn btn-link" onClick={() => { navigate("/reset") }}>Did you forget your password?</button>
                      </div>


                    </form>

                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center gradient-custom-2 d-none d-xl-block d-lg-block">
                  <img src="images/image-scl1.png"
                    alt="Login image" className="w-100 vh-100" style={{ objectFit: "cover", objectPosition: "left", maxHeight: "90vh" }}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;