import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function FAQ() {

    return (
        <div className="w-100 px-4 slide_bottom">
            <h1 className="w-100 display-1 text-center mt-4 mb-5">FAQ</h1>
            <div className="mb-3">
                <button className="w-100 rounded-5 text-start py-3 bg-white d-flex justify-content-between align-items-center custom-shadow">
                    <span className="fs-5">What is apersonalized loan</span>
                    <InfoOutlinedIcon sx={{fontSize: "30px"}} className="color" />
                </button>
            </div>
            <div className="mb-3">
                <button className="w-100 rounded-5 text-start py-3 bg-white d-flex justify-content-between align-items-center custom-shadow">
                    <span className="fs-5">How to apply for a loan</span>
                    <InfoOutlinedIcon sx={{fontSize: "30px"}} className="color" />
                </button>
            </div>
            <div className="mb-3">
                <button className="w-100 rounded-5 text-start py-3 bg-white d-flex justify-content-between align-items-center custom-shadow">
                    <span className="fs-5">How to apply for a loan</span>
                    <InfoOutlinedIcon sx={{fontSize: "30px"}} className="color" />
                </button>
            </div>
            <div className="mb-3">
                <button className="w-100 rounded-5 text-start py-3 bg-white d-flex justify-content-between align-items-center custom-shadow">
                    <span className="fs-5">How to apply for a loan</span>
                    <InfoOutlinedIcon sx={{fontSize: "30px"}} className="color" />
                </button>
            </div>

        </div>
    )
}

export default FAQ;