
enum FieldTypes {
    TEXT = "text",
    EMAIL = "email",
    FILE = "file",
    SELECT = "select",
    PASSWORD = "password",
    IMAGE = "image",
    TEXTAREA = "textarea",
    DATE = "date",
    DATETIME = "date-time",
    TIMESTAMP = "timestamp",
    NUMBER = "number",
    COLOR = "color",
    REFERENCE = "reference"
}

export default FieldTypes;