import React from "react";
import AddchartIcon from '@mui/icons-material/Addchart';

function EmptyMobile({message}: {message: any}) {

    return (
        <div className="d-flex justify-content-center mt-1">
            <div className="card-body w-75">
                <img src="/images/no_result.png" alt="" style={{position: "relative", left: "50%", transform: "translateX(-50%)"}}/>
                <h6 className="card-title text-center mt-0">{message}</h6>
            </div>
        </div>
    );

}

export default EmptyMobile;