import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../Contexts/AuthContext";
import EmptyMobile from "../Components/Extra/EmptyMobile";
import Utils from "../Models/Utils";

function RepaymentsScreen() {

    const { localState } = useContext(AuthContext);

    const params = useParams();

    const [selectedLoan, setSelectedLoan] = useState<any>(null);

    useEffect(() => {

        if (params.id) {
            setSelectedLoan(localState.Loans.find((lns: any) => (lns.id = params?.id)))
        }

    }, [params]);

    return selectedLoan ? (
        <div className="w-100 pt-3 px-2">

            {
                selectedLoan.repayment.length > 0 ? (
                    selectedLoan.repayment.map((rpt: any) => (
                        <div className="card rounded-3 mb-3 shadow-sm">
                            <div className="card-body">
                                <h2 className="card-title">{Utils.moneyDisplay(rpt.paid_amount)}ETB</h2>
                                <div className="d-flex mb-1">
                                    <div className="w-100">
                                        <div className="w-100 card-subtitle">Payment Date</div>
                                        <span className="card-subtitle text-muted">{Utils.convertISOToDate(rpt.payment_date)}</span>
                                    </div>
                                    <div className="w-100">
                                        <div className="w-100 card-subtitle">Transaction Id</div>
                                        <span className="card-subtitle text-muted">{rpt.transaction_id}</span>
                                    </div>
                                </div>

                                <hr />
                                <p className="lead fs-6">
                                    {rpt.remark}
                                </p>
                            </div>
                        </div>
                    ))
                ) : (<EmptyMobile message="No Repayments paid yet!" />)
            }

        </div>
    ) : (<EmptyMobile message="Loan Not Found!" />);
}

export default RepaymentsScreen;