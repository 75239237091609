import React from "react";

function Waiting(){

    return (
        <div className="waiting-container d-flex justify-content-center">
            <div className="my-waiting rounded shadow-lg mb-3">
                <img src="/images/EBIDIR-LOGO.png" alt="image" style={{width: "150px"}} />
                {/* <div className="text-center mb-0 mt-2" style={{fontSize: "15px"}}>Loading...</div> */}
                <div className="loading_parent">
                    <div className="loading_child"></div>
                </div>
            </div>
        </div>
    );
}

export default  Waiting;