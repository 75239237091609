import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login, information } from "./APIs/AuthAPI";
import AuthContext from "./Contexts/AuthContext";
import AlertContext from "./Contexts/AlertContext";
import Alert from "./Components/Extra/Alert";
import Waiting from "./Components/Extra/Waiting";
import { useCookies } from "react-cookie";
import Error from "./Views/Error";
import LoginPage from "./Views/Login";
import SideBar from "./Components/NavBars/SideBar";
import Account from "./Views/Account";
import Dashboard from "./Views/Dashboard";
import CreateCompany from "./Views/CreateCompany";
import AuthResult from "./Intefaces/AuthResult";
import LocalData from "./Intefaces/LocalData";
import ResetAccount from "./Views/ResetAccount";
import TableComponent from "./Views/TableComponent";
import ChatPage from "./Views/ChatPage";
import MainAPI from "./APIs/MainAPI";
import RequetLoan from "./Views/RequestLoan";
import Operators from "./Enums/Operators";
import FieldTypes from "./Enums/FiedTypes";
import UserRoles from "./Enums/UserRoles";
import { isMobile } from "react-device-detect";
import LoginScreen from "./Mobile/Login";
import Profile from "./Mobile/Profile";
import Forgot from "./Mobile/Forgot";
import MainScreen from "./Mobile/MainScreen";
import PloaDeatil_info from "./Mobile/PloanDetail_info";
import DashboardScreen from "./Mobile/Dashboard";
import RequestLoan from "./Mobile/RequestLoan";
import Loans from "./Mobile/Loans";
import RequestLoanDetail from "./Mobile/PloanDetail_info";
import LoanDetail from "./Mobile/LoanDetail";
import Setting from "./Mobile/Setting";
import Support from "./Mobile/Support";
import FAQ from "./Mobile/FAQ";
import UserLoans from "./Mobile/UserLoans";
import BusinessPlans from "./Mobile/BusinessPlans";
import Personal from "./Mobile/Personal";
import EconomicInfo from "./Mobile/EconomicInfo";
import BusinessPlanForm from "./Mobile/BusinessPlanForm";
import ChangePassword from "./Mobile/ChangePassword";
import RepaymentsScreen from "./Mobile/Repayments";
function App(params: any) {

    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [loggedUser, setLoggedUser] = useState<null | AuthResult>(null);
    const [cookies, setCookie, removeCookie] = useCookies(["login_token"]);
    const [authWaiting, setAuthWaiting] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [showWaiting, setWaiting] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"success" | "error" | "warning" | "info">("info");
    const [alertMessage, setMessage] = useState<string>("");
    const [menu, setMenu] = useState<boolean>(false);
    const [attachmentForm, setAttachmentForm] = useState<boolean>(true);
    const [localData, setLocalData] = useState<LocalData>({
        Users: [],
        PersonalUsers: [],
        CompanyUsers: [],
        BankUsers: [],
        Companies: [],
        Banks: [],
        BusinessPlan: [],
        LoanTypes: [],
        Loans: []
    });
    
    const [localState, setLocalState] = useState<any>({
        Users: [],
        PersonalUsers: [],
        CompanyUsers: [],
        BankUsers: [],
        Companies: [],
        Banks: [],
        BusinessPlan: [],
        Loans: [],
        PersonalInfo: [],
        EconomicInfo: [],
        LoanTypes: []
    });

    const [loanRequestForm, setLoanRequestForm] = useState<{bank: any, loanType: any}>({bank: null, loanType: null});

    useEffect(() => {

        const checkAuth = async (token: string) => {

            setTimeout(() => { setAuthWaiting(true); setWaiting(true); }, 1);
            let response = await information(token);
            await signIn(response.data);
            setAuthWaiting(false);
            setWaiting(false);

        };

        if (cookies.login_token && cookies.login_token != "") {
            checkAuth(cookies.login_token);
        }

    }, []);

    const setAlert = (
        message: string,
        type: "success" | "error" | "warning" | "info"
    ) => {

        setAlertType(type);
        setShowAlert(true);
        setMessage(message);

        setTimeout(() => {
            setShowAlert(false);
        }, 3000);

    }

    const signIn = async (user: AuthResult) => {
        if (user) {
            setCookie("login_token", user.Token, { path: "/" });
            setLoggedUser(user);
            setLoggedIn(true);
            await loadLocalData(user);
        }
    }

    const loadLocalData = async (logged_user: AuthResult) => {

        let temp_data = localData;
        let temp_state = localState;

        temp_data.Users = (await MainAPI.getAll(cookies.login_token, "user", 1, 1000)).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));

        temp_state.BusinessPlan = (await MainAPI.getAll(cookies.login_token, "businessplan", 1, 100, ((logged_user.Roles.includes(UserRoles.COMPANY) || logged_user.Roles.includes(UserRoles.PERSONAL)) ? {
            user_id: {
                operator: Operators.IS,
                type: FieldTypes.NUMBER,
                value: logged_user.Id
            }
        } : {}))).Items;
        temp_data.BusinessPlan = temp_state.BusinessPlan.map((cmp: any) => ({ value: cmp.id, label: `${cmp.sector} - ${cmp.score}` }));

        if (!logged_user.Roles.includes(UserRoles.COMPANY)) {
            temp_state.PersonalUsers = (await MainAPI.getAll(cookies.login_token, "user", 1, 100, ((logged_user.Roles.includes(UserRoles.PERSONAL)) ? {
                id: {
                    operator: Operators.IS,
                    type: FieldTypes.NUMBER,
                    value: logged_user.Id
                }
            } : {
                Role: {
                    operator: Operators.IS,
                    type: FieldTypes.TEXT,
                    value: UserRoles.PERSONAL
                }
            }))).Items;
            temp_data.PersonalUsers = temp_state.PersonalUsers.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));

            if(isMobile && logged_user.Roles.includes(UserRoles.PERSONAL)) {

                temp_state.PersonalInfo = (await MainAPI.getAll(cookies.login_token, "personal", 1, 100, {
                    user_id: {
                        operator: Operators.IS,
                        type: FieldTypes.NUMBER,
                        value: logged_user.Id
                    }
                })).Items;

                temp_state.EconomicInfo = (await MainAPI.getAll(cookies.login_token, "economic", 1, 100, {
                    user_id: {
                        operator: Operators.IS,
                        type: FieldTypes.NUMBER,
                        value: logged_user.Id
                    }
                })).Items;

            }

        }

        if (!logged_user.Roles.includes(UserRoles.PERSONAL)) {
            temp_state.Companies = (await MainAPI.getAll(cookies.login_token, "company", 1, 100, ((logged_user.Roles.includes(UserRoles.COMPANY)) ? {
                user_id: {
                    operator: Operators.IS,
                    type: FieldTypes.NUMBER,
                    value: logged_user.Id
                }
            } : {}))).Items;
            temp_data.Companies = temp_state.Companies.map((cmp: any) => ({ value: cmp.id, label: cmp.name }));

            temp_data.CompanyUsers = (await MainAPI.getAll(cookies.login_token, "user", 1, 100, ((logged_user.Roles.includes(UserRoles.COMPANY)) ? {
                id: {
                    operator: Operators.IS,
                    type: FieldTypes.NUMBER,
                    value: logged_user.Id
                }
            } : {
                Role: {
                    operator: Operators.IS,
                    type: FieldTypes.TEXT,
                    value: UserRoles.COMPANY
                }
            }))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));

            if(isMobile && logged_user.Roles.includes(UserRoles.COMPANY)) {
                
                temp_state.EconomicInfo = (await MainAPI.getAll(cookies.login_token, "companyeconomic", 1, 100, {
                    user_id: {
                        operator: Operators.IS,
                        type: FieldTypes.NUMBER,
                        value: logged_user.companyId
                    }
                })).Items;
            }

        }

        temp_state.Banks = (await MainAPI.getAll(cookies.login_token, "bank", 1, 100, ((logged_user.Roles.includes(UserRoles.BANK)) ? {
            user_id: {
                operator: Operators.IS,
                type: FieldTypes.NUMBER,
                value: logged_user.Id
            }
        } : {}))).Items;

        temp_data.Banks = temp_state.Banks.map((cmp: any) => ({ value: cmp.id, label: cmp.name }));

        temp_data.BankUsers = (await MainAPI.getAll(cookies.login_token, "user", 1, 100, ((logged_user.Roles.includes(UserRoles.PERSONAL)) ? {
            id: {
                operator: Operators.IS,
                type: FieldTypes.NUMBER,
                value: logged_user.Id
            }
        } : {
            Role: {
                operator: Operators.IS,
                type: FieldTypes.TEXT,
                value: UserRoles.BANK
            }
        }))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));

        temp_state.Loans = (await MainAPI.getAllRelated(cookies.login_token, "loan", 1, 100, (isMobile && (logged_user.Roles.includes(UserRoles.COMPANY) || logged_user.Roles.includes(UserRoles.PERSONAL))) ? ({
            user_id: {
                operator: Operators.IS,
                type: FieldTypes.NUMBER,
                value: logged_user.Id
            }
        }) : (logged_user.Roles.includes(UserRoles.BANK)) ? ({
            bank: {
                operator: Operators.IS,
                type: FieldTypes.NUMBER,
                value: logged_user.bankId
            }
        }) : ({}))).Items;

        temp_data.Loans = temp_state.Loans.map((cmp: any) => ({ value: cmp.id, label: `${cmp.bank_loan_bankTobank.name} - ${cmp.loan_amount}` }));

        temp_state.LoanTypes = (await MainAPI.getAllRelated(cookies.login_token, "loantype", 1, 1000, ((logged_user.Roles.includes(UserRoles.BANK)) ? {
            bank_id: {
                operator: Operators.IS,
                type: FieldTypes.NUMBER,
                value: logged_user.bankId
            }
        } : {}))).Items;

        temp_data.LoanTypes = temp_state.LoanTypes.map((cmp: any) => ({ value: cmp.id, label: cmp.name }));

        setLocalData(temp_data);
        setLocalState(temp_state);
    }

    return (
        <AlertContext.Provider value={{
            showAlert, alertType, setAlertType, setAlert, setWaiting, menu, setMenu, attachmentForm, setAttachmentForm
        }}>
            <AuthContext.Provider value={{
                isLoggedIn, loggedUser, setLoggedUser, setLoggedIn, setCookie, cookies, removeCookie, authWaiting, localData, localState, setLocalState, signIn, loanRequestForm, setLoanRequestForm
            }}>
                <BrowserRouter>
                    {
                        !authWaiting && isMobile && (
                            !isLoggedIn ? (
                                <Routes>
                                    <Route path="/" element={<LoginScreen />} />
                                    <Route path="/reset" element={<Forgot />} />
                                    <Route path="*" element={<Error />} />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route path="/" element={<MainScreen />} >
                                        <Route path="" element={<DashboardScreen />} />
                                        <Route path="profile" element={<Profile />} />
                                        <Route path="info" element={<Personal />} />
                                        <Route path="economic_info" element={<EconomicInfo />} />
                                        <Route path="loans_list" element={<UserLoans />} />
                                        <Route path="business_plan_list" element={<BusinessPlans />} />
                                        <Route path="business_plan/:id" element={<BusinessPlanForm />} />
                                        <Route path="loan_info" element={<PloaDeatil_info />} />
                                        <Route path="select_bank" element={<RequestLoan />} />
                                        <Route path="select_type" element={<Loans />} />
                                        <Route path="set_loan_detail" element={<RequestLoanDetail />} />
                                        <Route path="loan_detail/:id" element={<LoanDetail />} />
                                        <Route path="support" element={<Support />} />
                                        <Route path="change_password" element={<ChangePassword />} />
                                        <Route path="faq" element={<FAQ />} />
                                        <Route path="repayments/:id" element={<RepaymentsScreen />} />
                                        <Route path="*" element={<Error />} />
                                    </Route>
                                </Routes>
                            )
                        )
                    }

                    {

                        !authWaiting && !isMobile && (

                            !isLoggedIn ? (

                                <Routes>
                                    <Route path="/" element={<LoginPage />} />
                                    <Route path="/reset" element={<ResetAccount />} />
                                </Routes>

                            ) : (
                                <Routes>
                                    <Route path="/chat" element={<ChatPage />} />
                                    <Route path="/list/:name" element={<TableComponent />} />
                                    <Route path="/form/:name/:r_id" element={<CreateCompany />} />
                                    <Route path="/profile" element={<Account />} />
                                    <Route path="/loanrequest" element={<RequetLoan />} />
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="*" element={<Error />} />
                                </Routes>
                            )
                        )
                    }
                    {showAlert ? (<Alert message={alertMessage} color={alertType} />) : ""}
                    {showWaiting ? (<Waiting />) : ""}
                    {(menu && !isMobile) ? (<SideBar />) : ""}
                </BrowserRouter>
            </AuthContext.Provider>
        </AlertContext.Provider>
    );

}

export default App;