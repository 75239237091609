
const LoanStatus = {
    Approved: 'approved',
    Requested: 'requested',
    Rejected: 'rejected',
    Received: 'received',
    Repaid: 'repaid',
    Cancelled: 'cancelled',
    BankApproved: 'bank_approved',
    BankReject: 'bank_reject',

};

export default LoanStatus;