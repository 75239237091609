import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../Contexts/AuthContext";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import MainAPI from "../APIs/MainAPI";
import AlertContext from "../Contexts/AlertContext";
import EducationStatus from "../Enums/EducationStatus";
import CriminalRecord from "../Enums/CriminalRecord";
import MaritalStatus from "../Enums/MaritalStatus";
import Utils from "../Models/Utils";
import UserRoles from "../Enums/UserRoles";
import LegalStatus from "../Enums/LefalStatus";
import Sectors from "../Enums/Sectors";

function Personal() {

    const {loggedUser, localState, cookies, setLocalState} = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);

    const params = useParams();
    const localEducationLevel = [
        { value: EducationStatus.BelowHighSchool, label: "Below High School" },
        { value: EducationStatus.HighSchool, label: "High School" },
        { value: EducationStatus.Diploma, label: "Deploma" },
        { value: EducationStatus.Bachelors, label: "Bachelors Degree" },
        { value: EducationStatus.Masters, label: "Masters Degree" },
        { value: EducationStatus.PHD, label: "PHD" }
    ];
    
    const localCriminalRecord = [
        { value: CriminalRecord.No, label: "No" },
        { value: CriminalRecord.PastFive, label: "Past/Five Years" },
        { value: CriminalRecord.MoreThanFive, label: "More Than Five Years" },
    ];

    const localMaritalStatus = [
        { value: MaritalStatus.Married, label: "Married" },
        { value: MaritalStatus.Unmerried, label: "Not Married" },
    ];

    const localLegalStatus = [
        { value: LegalStatus.PLC, label: "Public/Private Limited" },
        { value: LegalStatus.ProprietorShip, label: "Proprietorship" },
        { value: LegalStatus.PartnerShip, label: "Prtnership" }
    ];
    const localSectors = [
        { value: Sectors.Agricalture, label: "Agricalture" },
        { value: Sectors.Service, label: "Service" },
        { value: Sectors.Tech, label: "Technology" },
        { value: Sectors.Manufacture, label: "Manufacturing" },
        { value: Sectors.Construction, label: "Construction" }
    ];

    const [editing, setEditing] = useState<boolean>(false);
    const [isCompany, setIsCompany] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [personalInfo, setPersonalInfo] = useState<any>({
        id: 0,
        user_id: 0,
        tin: "",
        age: 0,
        education: "",
        marital_status: "",
        dependants: 1,
        criminal: "",
        score: 0
    });
    
    const [companyInfo, setCompanyInfo] = useState<any>({
        id: 0,
        name: "",
        user_id: 0,
        tin: "",
        sector: "",
        legal_status: "",
        score: 0
    });

    useEffect(() => {

        let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
        setIsCompany(is_company);

        if(localState.PersonalInfo.length > 0 || localState.Companies.length > 0) {

            if(is_company) {
                setCompanyInfo(localState.Companies[0]);
            } else {
                setPersonalInfo(localState.PersonalInfo[0]);
            }
            setIsNew(false);

        } else {

            if(is_company) {
                setCompanyInfo((pi: any) => ({...pi, user_id: loggedUser.Id}));
            } else {
                setPersonalInfo((pi: any) => ({...pi, user_id: loggedUser.Id}));
            }

        }

    }, [localState]);
    
    const submitForm = async (event: any) => {

        
        try {
            
            setTimeout(() => {setEditing(false);}, 3);
            
            if(isCompany){

                let result =  isNew ? await MainAPI.createNew(cookies.login_token, "company", companyInfo) : await MainAPI.update(cookies.login_token, "company", companyInfo);
                setAlert(`Company info has been ${isNew ? "created" : "updated"} successfully`, "success");
                setLocalState((ls: any) => ({...ls, ["PersonalInfo"]: [result.data]}));

            } else {

                let result =  isNew ? await MainAPI.createNew(cookies.login_token, "personal", personalInfo) : await MainAPI.update(cookies.login_token, "personal", personalInfo);
                setAlert(`Personal info has been ${isNew ? "created" : "updated"} successfully`, "success");
                setLocalState((ls: any) => ({...ls, ["PersonalInfo"]: [result.data]}));

            }

        } catch (error: any) {
            setAlert(error.message, "error");
        }

    }

    const inputsOnChange = (event: any) => {

        console.log("working")
        let value: any = null;
        if(event.target.name == "age" || event.target.name == "dependants"){
            value = parseInt(event.target.value);
        }else {
            value = event.target.value;
        }

        if(isCompany) {
            setCompanyInfo((ei: any) => ({...ei, [event.target.name]: value}));
        } else {
            setPersonalInfo((ei: any) => ({...ei, [event.target.name]: value}));
        }

    }

    return (personalInfo || companyInfo) && (
        <div className="w-100 px-4 slide_right">

            <div className="d-flex mb-3 justify-content-between mt-2 align-items-center">
                <h2 className="card-title text-center mb-3 mt-2">{isCompany ? "Company" : "Personal"} Info</h2>
                <div className="d-flex justify-content-end">
                    <span className="p-2 shadow-sm rounded-3 bg-white" style={{height: "max-content"}}>
                        <EditIcon sx={{fontSize: "30px", display: (editing ? "none" : "")}} className="color" onClick={() => {setEditing(true);}} />
                        <CloseIcon sx={{fontSize: "30px", display: (!editing ? "none" : "")}} className="color" onClick={() => {setEditing(false);}} />
                    </span>
                </div>
            </div>

            <div className="w-100 mb-4">
                <h5 className="card-title">{loggedUser.FullName}</h5>
                <div className="card-subtitle">{loggedUser.Phone}</div>
                <div className="card-subtitle mb-3">{loggedUser.Email}</div>
            </div>

            {
                isCompany ? (
                    <div className="mb-3">
                        <label className="mb-2">Company Name</label>
                        {
                            editing ? 
                                (<input 
                                    type="text"
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" 
                                    placeholder="Company Name" 
                                    name="name" 
                                    value={companyInfo.name}
                                    onChange={inputsOnChange}
                                    
                                />) : 
                                (<div className="w-100 fs-4">{ companyInfo.name ? companyInfo.name : "Not Set" }</div>)
                        }
                    </div>
                ) : ("")
            }

            {
                isCompany ? (
                    <div className="mb-3">
                        <label className="mb-2">TIN Number</label>
                        {
                            editing ? 
                                (<input 
                                    type="text" 
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" 
                                    placeholder="TIN Number" 
                                    name="tin" 
                                    value={companyInfo.tin}
                                    onChange={inputsOnChange} 
                                />) : 
                                (<div className="w-100 fs-4">{ companyInfo.tin ? companyInfo.tin : "Not Set" }</div>)
                        }
                    </div>
                ) : (
                    <div className="mb-3">
                        <label className="mb-2">TIN Number</label>
                        {
                            editing ? 
                                (<input 
                                    type="text" 
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" 
                                    placeholder="TIN Number" 
                                    name="tin" 
                                    value={personalInfo.tin}
                                    onChange={inputsOnChange} 
                                />) : 
                                (<div className="w-100 fs-4">{ personalInfo.tin ? personalInfo.tin : "Not Set" }</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Education Level</label>
                        {
                            editing ? 
                                (<select 
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow"
                                    name="education" 
                                    value={personalInfo.education}
                                    onChange={inputsOnChange} 
                                >
                                    <option value="">None</option>
                                    {
                                        localEducationLevel.map(el => (
                                            <option selected={el.value == personalInfo.education} value={el.value}>{el.label}</option>
                                        ))
                                    }
                                </select>) : 
                                (<div className="w-100 fs-4">{ personalInfo.education ? Utils.getFromArray("value", personalInfo.education, "label", localEducationLevel) : "Not Set" }</div>)
                        }
                    </div>
                )
            }
            
            {
                isCompany ? (
                    <div className="mb-3">
                        <label className="mb-2">Legal Status</label>
                        {
                            editing ? 
                                (<select 
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow"
                                    name="legal_status" 
                                    value={companyInfo.legal_status}
                                    onChange={inputsOnChange} 
                                >
                                    <option value="">None</option>
                                    {
                                        localLegalStatus.map(el => (
                                            <option selected={el.value == companyInfo.legal_status} value={el.value}>{el.label}</option>
                                        ))
                                    }
                                </select>) : 
                                (<div className="w-100 fs-4">{ companyInfo.legal_status ? Utils.getFromArray("value", companyInfo.legal_status, "label", localLegalStatus) : "Not Set" }</div>)
                        }
                    </div>
                ) : ("")
            }

            {
                isCompany ? (
                    <div className="mb-3">
                        <label className="mb-2">Working Sector</label>
                        {
                            editing ? 
                                (<select 
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow"
                                    name="sector" 
                                    value={companyInfo.sector}
                                    onChange={inputsOnChange} 
                                >
                                    <option value="">None</option>
                                    {
                                        localSectors.map(el => (
                                            <option selected={el.value == companyInfo.sector} value={el.value}>{el.label}</option>
                                        ))
                                    }
                                </select>) : 
                                (<div className="w-100 fs-4">{ companyInfo.sector ? Utils.getFromArray("value", companyInfo.sector, "label", localSectors) : "Not Set" }</div>)
                        }
                    </div>
                ) : ("")
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Number of Dependants</label>
                        {
                            editing ? 
                                (<input 
                                    type="number"
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" 
                                    placeholder="Number of Dependants" 
                                    name="dependants" 
                                    value={personalInfo.dependants}
                                    onChange={inputsOnChange}
                                    
                                />) : 
                                (<div className="w-100 fs-4">{ personalInfo.dependants ? personalInfo.dependants : "Not Set" }</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Age</label>
                        {
                            editing ? (
                            <input 
                                type="number" 
                                className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow"
                                placeholder="How Old Are you?"
                                name="age"
                                onChange={inputsOnChange}
                                value={personalInfo.age}
                            />) : 
                            (<div className="w-100 fs-4">{ personalInfo.age ? personalInfo.age : "Not Set" }</div>)
                        }
                    </div>
                ) 
            }
            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Marital Status</label>
                        {
                            editing ? 
                                (<select
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow"
                                    name="marital_status"
                                    value={personalInfo.marital_status}
                                    onChange={inputsOnChange}
                                >
                                    <option value="">None</option>
                                    {
                                        localMaritalStatus.map(el => (
                                            <option selected={el.value == personalInfo.marital_status} value={el.value}>{el.label}</option>
                                        ))
                                    }
                                </select>) : 
                                (<div className="w-100 fs-4">{ personalInfo.marital_status ? Utils.getFromArray("value", personalInfo.marital_status, "label", localMaritalStatus) : "Not Set" }</div>)
                        }
                    </div>
                )
            }
            {
                !isCompany && (
                    <div className="mb-4">
                        <label className="mb-2">Criminal Record</label>
                        {
                            editing ? (
                                <select 
                                    className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" 
                                    name="criminal"
                                    value={personalInfo.criminal}
                                    onChange={inputsOnChange}
                                >
                                    <option value="">None</option>
                                    {
                                        localCriminalRecord.map(el => (
                                            <option selected={el.value == personalInfo.criminal} value={el.value}>{el.label}</option>
                                        ))
                                    }
                                </select>) : 
                            (<div className="w-100 fs-4">{ personalInfo.criminal ? Utils.getFromArray("value", personalInfo.criminal, "label", localCriminalRecord) : "Not Set" }</div>)
                        }
                    </div>
                )
            }

            {editing && !isNew && (<button className="w-100 rounded-5 p-2 fs-4 button btn mb-2" onClick={submitForm}>Update</button>)}
            {editing && isNew && (<button className="w-100 rounded-5 p-2 fs-4 button btn mb-2" onClick={submitForm}>Create</button>)}

        </div>
    )
}
export default Personal;