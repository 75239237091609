
enum EducationStatus {
    BelowHighSchool = "bellow_hs",
    HighSchool = "highSchool",
    Diploma = "diploma",
    Bachelors = "bachelors",
    Masters = "masters",
    PHD = "phd",
}

export default EducationStatus;