import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { props } from "../APIs/api";
import AuthContext from "../Contexts/AuthContext";
import Utils from "../Models/Utils";
import EmptyMobile from "../Components/Extra/EmptyMobile";

function UserLoans() {

    const {localState} = useContext(AuthContext);

    const navigate = useNavigate();

    return (
        <div className="p-3 zoom_out">

            <h5 className="card-title text-center mb-4">Requested Loans</h5>

            {
                localState.Loans.length > 0 ? (
                    localState.Loans.map((ln: any) => (
                        <div
                            className="px-3 rounded-5 bg-white mb-3 w-100 border d-flex align-items-center shadow-sm"
                            style={{cursor: "pointer"}}
                            onClick={() => {navigate(`/loan_detail/${ln.id}`);}}
                        >
                            <div className="me-3 py-3">
                                <img src={`${props.baseURL}file/${ln.bank_loan_bankTobank.image}`} alt="bank logo" style={{width: "35px"}} />
                            </div>
                            <div className="w-100">
                                <div className="color" style={{fontSize: "15px"}}>Student Loan</div>
                                <div className="text-dark" style={{fontSize: "16px"}}>{Utils.moneyDisplay(ln.loan_amount)}</div>
                            </div>
                            <div className="py-3">
                                <button className="btn btn-sm btn-link color" style={{fontSize: "13px"}}>View</button>
                            </div>
                        </div>
                    ))
                ) : (<EmptyMobile message="No loan requested yet!" />)
                
            }
        </div>
    );
}

export default UserLoans;