import React, { useContext, useState } from "react";
import { Authorized } from "../APIs/api";
import AlertContext from "../Contexts/AlertContext";
import AuthContext from "../Contexts/AuthContext";
import { isMobile } from "react-device-detect";

function ChangePassword() {

    const { loggedUser, authWaiting, cookies } = useContext(AuthContext);
    const { setAlert, setWaiting } = useContext(AlertContext);

    const [fields, setFields] = useState<any>({
        old_password: "",
        new_password: "",
        confirm_password: ""
    });

    const submit = async () => {

        setTimeout(() => { setWaiting(true) }, 1);
        try {

            if(fields.old_password == "" || fields.new_password == "" || fields.confirm_password == "") {
                setTimeout(() => { setWaiting(false) }, 1);
                throw new Error("All input fields are required!");
            }

            if(fields.new_password != fields.confirm_password){
                setTimeout(() => { setWaiting(false) }, 1);
                throw new Error("Password confirmation does not match");
            }

            let result = await Authorized(cookies.login_token).bodyRequest("put", "crud/changePassword", {
                data: {
                    oldPassword: fields.old_password,
                    newPassword: fields.new_password,
                    id: loggedUser.Id
                }
            });
            setAlert("Password Changed Sucessfully Use this password on your next login", "success");
        } catch (error: any) {
            setAlert(error.message, "error");
        }

        setTimeout(() => { setWaiting(false) }, 1);

    }

    const inputChange = (input: ("old" | "new" | "confirm"), value: any) => {
        if (input == "old") {
            setFields({ ...fields, old_password: value })
        }
        if (input == "new") {
            setFields({ ...fields, new_password: value })
        }
        if (input == "confirm") {
            setFields({ ...fields, confirm_password: value })
        }
    }

    return (
        <div className="w-100 px-4 zoom_in">
            <div className="display-6 lead text-center mb-5 mt-3">Change User Password</div>
            <label className="mb-2">Current Password</label>
            <div className="mb-3">
                <input type="text" className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" placeholder="Current Password" onChange={(event: any) => { inputChange("old", event.target.value) }} />
            </div>
            <label className="mb-2">New Password</label>
            <div className="mb-3">
                <input type="text" className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" placeholder="Create New Password" onChange={(event: any) => { inputChange("new", event.target.value) }}/>
            </div>
            <label className="mb-2">Confirm Password</label>
            <div className="mb-3">
                <input type="text" className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" placeholder="Confirm New Password" onChange={(event: any) => { inputChange("confirm", event.target.value) }}/>
            </div>

            <button className="w-100 rounded-5 p-2 fs-4 mt-5 button btn" onClick={submit} >
                Change Password
            </button>
        </div>

    )
}

export default ChangePassword;