import React, { useState, useEffect, useContext } from 'react';
import MainAPI from '../APIs/MainAPI';
import AlertContext from '../Contexts/AlertContext';
import AuthContext from '../Contexts/AuthContext';
import {  props } from "../APIs/api";



const SlidingBanksData = () => {
  const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
	const { loggedUser, cookies, localData } = useContext(AuthContext);
  const [cardsData, setcardsData] = useState<any[]>([]);
  
  const [translateX, setTranslateX] = useState(0);
  const cardWidth = 250;
  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTranslateX((prevTranslateX) => prevTranslateX - 1);
    }, 100);
    async function getBanksList() {
			try {

				var token = cookies.login_token
				let response = await MainAPI.getAll(token, "bank", 1, 100);
				var banks = response.Items
        console.log(banks)
				setcardsData(banks)
			

				

			} catch (error: any) {
				setWaiting(false);
				setAlert(error.message, "error");
			}
		}
		getBanksList()
    return () => clearInterval(intervalId);

    

  }, []);
  // style={{ transform: `translateX(${translateX}px)` }}
  return (
    <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
    <div
      className="sliding-banks-data"
      style={{ transform: `translateX(${translateX}px)` }}
    >
      

        {cardsData.map((card, index) => (
          
          <div className="card" style={{ width: "300px", margin: "20px" , border: "none", backgroundColor: card.logoColor , display: 'inline-block' }}>
            <div className="card-body" style={{ color: "white", backgroundColor: card.logoColor, margin: "0px", padding: "0px" ,border:"none"}}>
              <div className="d-flex align-items-start" style={{ backgroundColor: card.logoColor ,border:"none"}}>
                <div className="col" style={{ margin: "0px", padding: "0px", backgroundColor: "white",border:"none" }}>
                  <img
                    src={`${props.baseURL}file/${card.image}`}
                    className="card-img-top"
                    alt="bunna bank"
                    style={{ objectFit: "fill", height: "120px", width: "151px", border:"none" }}
                  />
                </div>
                <div className="col p-1" style={{ margin: "5px", overflow: "hidden" }}>
                  <h6 className="card-title" style={{textWrap: "wrap", wordBreak: "break-word"}}>{card.name}</h6>
                  <p className="card-text">{card.loan &&<span>{card.loan}</span> } Loans</p>
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  
  );
};

export default SlidingBanksData;

