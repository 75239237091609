import React from "react";
import { useNavigate } from "react-router-dom";

function Forgot() {

    const navigate = useNavigate();

    return (
        <div className="w-100 px-4">
            
            <div className="w-100 d-flex justify-content-center mt-5 mb-5">
                <img className="w-50" src="./images/Vector.png" alt="e-bidir logo" />
            </div>
            <h5 className="mb-3">Phone Number</h5>
            <div className="mb-3">
                <input type="text" className="w-100 rounded-5 p-2 px-3 fs-4 custom-shadow" placeholder="" />
            </div>
            <button className="w-100 rounded-5 p-2 fs-4 mt-5 button">Send</button>
        </div>

    )
}

export default Forgot;