import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../Contexts/AuthContext";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AlertContext from "../Contexts/AlertContext";
import MainAPI from "../APIs/MainAPI";
import UserRoles from "../Enums/UserRoles";
import Utils from "../Models/Utils";

function EconomicInfo() {

    const {loggedUser, localState, cookies, setLocalState} = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);

    const [editing, setEditing] = useState<boolean>(false);
    const [isCompany, setIsCompany] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [economicInfo, setEconomicInfo] = useState<any>({
        field_of_employment: "",
        experience: 0,
        source_of_income: 0,
        assets: "",
        
        user_id: 0,
        current_loans: 0,
        repaid_loans: 0,
        dti: 0,
        score: 0,
        total_income: 0,
        id: 0
    });

    const [companyEconomicInfo, setCompanyEconomicInfo] = useState<any>({
        name: "",
        employee_count: 0,
        company_age: 0,
        assets: "",
        user_id: 0,
        current_loans: 0,
        repaid_loans: 0,
        dti: 0,
        fccr: 0,
        ebit: 0,
        fcbt: 0,

        score: 0,
        total_income: 0,
        id: 0
    });

    useEffect(() => {

        let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
        setIsCompany(is_company);

        if(localState.EconomicInfo.length > 0) {

            if(is_company) {
                setCompanyEconomicInfo(localState.EconomicInfo[0]);
            } else {
                setEconomicInfo(localState.EconomicInfo[0]);
            }

            setIsNew(false);

        } else {
            setEconomicInfo((pi: any) => ({...pi, user_id: loggedUser.Id}));
        }

    }, [localState]);

    const inputsOnChange = (event: any) => {

        let value: any = null;
        if(event.target.name == "experience" || event.target.name == "source_of_income" || event.target.name == "total_income") {
            value = parseInt(event.target.value);
        }else {
            value = event.target.value;
        }

        if(isCompany) {
            setCompanyEconomicInfo((ei: any) => ({...ei, [event.target.name]: value}));
        }else {
            setEconomicInfo((ei: any) => ({...ei, [event.target.name]: value}));
        }
    }

    const submitForm = async (event: any) => {

        try {
            
            setTimeout(() => {setEditing(false);}, 3);

            let result: any = null;

            if(isCompany) {
                result =  isNew ? await MainAPI.createNew(cookies.login_token, "companyeconomic", companyEconomicInfo) : await MainAPI.update(cookies.login_token, "companyeconomic", companyEconomicInfo);
            } else {
                result =  isNew ? await MainAPI.createNew(cookies.login_token, "economic", economicInfo) : await MainAPI.update(cookies.login_token, "economic", economicInfo);
            }

            setAlert(`Economic info has been ${isNew ? "created" : "updated"} successfully`, "success");
            setLocalState((ls: any) => ({...ls, ["EconomicInfo"]: [result.data]}));

        } catch (error: any) {
            setAlert(error.message, "error");
        }

    }

    return (
        <div className="w-100 px-4 slide_bottom">

            <div className="d-flex mb-3 justify-content-between mt-2 align-items-center">
                <h2 className="card-title text-center mb-3 mt-2"> Economic Info</h2>
                <div className="d-flex justify-content-end">
                    <span className="p-2 shadow-sm rounded-3 bg-white" style={{height: "max-content"}}>
                        <EditIcon sx={{fontSize: "30px", display: (editing ? "none" : "")}} className="color" onClick={() => {setEditing(true);}} />
                        <CloseIcon sx={{fontSize: "30px", display: (!editing ? "none" : "")}} className="color" onClick={() => {setEditing(false);}} />
                    </span>
                </div>
            </div>

            <div className="w-100 mb-4">
                <h5 className="card-title">{loggedUser.FullName}</h5>
                <div className="card-subtitle">Unpaid Loans: {economicInfo.current_loans}</div>
                <div className="card-subtitle mb-3">Paid Loans: {economicInfo.repaid_loans}</div>
            </div>

            {
                isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Company Name</label>
                        {
                            editing ? 
                                (<input type="text" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={companyEconomicInfo.name} name="name" onChange={inputsOnChange} placeholder="Company Name" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{companyEconomicInfo.name ? companyEconomicInfo.name : "Not Set"}</div>)
                        }
                    </div>
                )
            }
            {
                isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Employee Count</label>
                        {
                            editing ? 
                                (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={companyEconomicInfo.employee_count} name="employee_count" onChange={inputsOnChange} placeholder="Employee Count" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{companyEconomicInfo.employee_count ? companyEconomicInfo.employee_count : "Not Set"}</div>)
                        }
                    </div>
                )
            }
            {
                isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Company Age</label>
                        {
                            editing ? 
                                (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={companyEconomicInfo.company_age} name="company_age" onChange={inputsOnChange} placeholder="Company Age" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{companyEconomicInfo.company_age ? companyEconomicInfo.company_age : "Not Set"}</div>)
                        }
                    </div>
                )
            }
            {
                isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Total Monthly Income</label>
                        {
                            editing ? 
                                (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={companyEconomicInfo.total_income} name="total_income" onChange={inputsOnChange} placeholder="Total Monthly Income" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{companyEconomicInfo.total_income ? Utils.moneyDisplay(companyEconomicInfo.total_income) : "Not Set"}</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Field of Employment</label>
                        {
                            editing ? 
                                (<input type="text" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.field_of_employment} name="field_of_employment" onChange={inputsOnChange} placeholder="Employment Field" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.field_of_employment ? economicInfo.field_of_employment : "Not Set"}</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Years of Experience</label>
                        {
                            editing ? 
                                (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.experience} name="experience" onChange={inputsOnChange} placeholder="Years of Experience" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.experience ?? "Not Set"}</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Income Source</label>
                        {
                            editing ? 
                                (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.source_of_income} name="source_of_income" onChange={inputsOnChange} placeholder="Source of Income" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.source_of_income ?? "Not Set"}</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-3">
                        <label className="mb-2">Total Income</label>
                        {
                            editing ? 
                                (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.total_income} name="total_income" onChange={inputsOnChange} placeholder="Total Income" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.total_income ?? "Not Set" }</div>)
                        }
                    </div>
                )
            }

            {
                !isCompany && (        
                    <div className="mb-4">
                        <label className="mb-2">Debt to Income Ration (DTI)</label>
                        <div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.dti}</div>
                    </div>
                )
            }

            {
                !isCompany && (
                    <div className="mb-4">
                        <label className="mb-2">Assets</label>
                        {
                            editing ? 
                                (<textarea rows={6} className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.assets} name="assets" onChange={inputsOnChange} placeholder="Assets you have" />) : 
                                (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.assets ? economicInfo.assets : "Not Set"}</div>)
                        }
                    </div>
                )
            }

            {editing && isNew && (<button className="w-100 rounded-5 p-2 fs-4 button btn mb-2" onClick={submitForm} >Create</button>)}
            {editing && !isNew && (<button className="w-100 rounded-5 p-2 fs-4 button btn mb-2" onClick={submitForm} >Update</button>)}

        </div>
    )
}
export default EconomicInfo;