import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AuthContext from "../Contexts/AuthContext";
import { Login } from "../APIs/AuthAPI";

function LoginScreen() {

    const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
    const { setLoggedUser, setLoggedIn, setCookie, signIn } = useContext(AuthContext);

    const [fields, setFields] = useState<{ Phone: string, Password: string }>({
        Phone: "",
        Password: ""
    });

    const style = {
        mobile: {
        width: "100%",
        // height: "100%"
        },
        desktop: {
        width: "35%"
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const fieldSetter = (type: ("Phone" | "Password"), value: any) => {
        setFields({ ...fields, [type]: value });
    }

    const submitForm = async (event: any) => {
        event.preventDefault();

        setTimeout(() => { setWaiting(true) }, 1);
        try {
            let response = await Login(fields.Phone, fields.Password);

            // setLoggedUser(response);
            // setCookie("login_token", response.Token, { path: "/" });
            // setLoggedIn(true);

            // setAlert("working", "info");
            await signIn(response);
            setWaiting(false);
            navigate("/");

        } catch (error: any) {
        setWaiting(false);
        setAlert(error.message, "error");
        }


    }

    return (
        <form onSubmit={submitForm} className="w-100 px-4">
            <div className="w-100 d-flex justify-content-center mt-5 mb-2">
                <img className="w-50" src="./images/Vector.png" alt="e-bidir logo" />
            </div>
            <h3 className="text-center h-50">Welcome,</h3>
            <h5 className="text-center mb-5 color">Log in to continue</h5>
            <h5 className="mb-3">Phone Number</h5>
            <div className="mb-3">
                <input type="text" onChange={(event: any) => {fieldSetter("Phone", event.target.value)}} className="w-100 py-2 px-3 custom-shadow custom-input" placeholder="Your Phone Number" />
            </div>
            <h5 className="mb-3">Password</h5>
            <div className="mb-3">
                <input type="password" onChange={(event: any) => {fieldSetter("Password", event.target.value)}} className="w-100 py-2 px-3 custom-shadow custom-input" placeholder="Password" />
            </div>
            <button type="button" className="btn btn-link btn-sm text-decoration-none mb-3 color" onClick={() => {navigate("/reset")}}>
                Forgot Password
            </button>
            <button type="submit" className="w-100 rounded-5 p-2 fs-4 mt-5 button btn" >Log in</button>
        </form>
    )
}

export default LoginScreen;