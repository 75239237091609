import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Contexts/AuthContext";
import { props } from "../APIs/api";
import EmptyMobile from "../Components/Extra/EmptyMobile";

function Loans() {

    const navigate = useNavigate();
    const {loanRequestForm, localState, setLoanRequestForm} = useContext(AuthContext);
    const [bankLoanTypes, setBankLoanTypes] = useState<any[]>([]);

    useEffect(() => {

        if(!loanRequestForm.bank) {
            navigate("/select_bank");
        } 
        // else if(loanRequestForm.loanType) {
        //     navigate("/set_loan_detail");
        // }

        setBankLoanTypes(localState.LoanTypes.filter((lt: any) => (lt.bank_id == loanRequestForm.bank.id)));

    }, []);

    const selectLoanType = (type: any) => {
        console.log("loan type selected ", type);
        setLoanRequestForm((lrf: any) => ({...lrf, loanType: type}));
    }

    return loanRequestForm.bank && (
        <div className="w-100 px-4 slide_left">
            <div className="d-flex justify-content-between mt-3">
                <div className="rounded-5 p-3 custom-shadow" style={{ background: 'transparent', border: 'none', outline: 'none' }}>
                    <img src={`${props.baseURL}file/${loanRequestForm?.bank.image}`} width="80" alt="" className="rounded-4" />
                </div>
                <div className="mt-2">
                    <h2 className="mb-2 text-end">{loanRequestForm?.bank.name}</h2>
                    <p className="text-end color">Works with Ebidir</p>
                </div>
            </div>
            <p className="mt-4 color">Select the type of loan</p>
            {
                bankLoanTypes.length > 0 ? (
                    bankLoanTypes.map((lt: any) => (
                        <div className="my-4">
                            <div className="w-100 rounded-5 d-flex justify-content-between py-2 px-3 fs-4 custom-shadow" onClick={ () => {
                                selectLoanType(lt);
                                navigate("/set_loan_detail");
                            }}>
                                <strong className="me-2 text-primary">{(lt.rate * 100).toFixed(2)}%</strong>
                                {lt.name}
                                <button className="fs-6 color" style={{ background: 'transparent', border: 'none', outline: 'none' }}>apply</button>
                            </div>
    
                        </div>
                    ))
                ) : (<EmptyMobile message="No Loan type definde!" />)
            }

        </div>


    )
}

export default Loans;