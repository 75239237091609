import React, { useContext, useEffect, useState } from "react";
import Utils from "../Models/Utils";
import AuthContext from "../Contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { props } from "../APIs/api";
import LoanStatus from "../Enums/LoanStatus";
import MainAPI from "../APIs/MainAPI";
import AlertContext from "../Contexts/AlertContext";
import ArrowForwardIcon from '@mui/icons-material/ChevronRightOutlined';

function LoanDetail() {

    const {localState, cookies, setLocalState} = useContext(AuthContext);
    const {setWaiting, setAlert} = useContext(AlertContext);

    const params = useParams();
    const navigate = useNavigate();

    const state_colors: any = {
        [LoanStatus.Approved]: "bg-secondary text-white",
        [LoanStatus.BankApproved]: "bg-dark text-white",
        [LoanStatus.Cancelled]: "bg-danger text-white",
        [LoanStatus.Rejected]: "bg-danger text-white",
        [LoanStatus.BankReject]: "bg-danger text-white",
        [LoanStatus.Requested]: "bg-primary text-white",
        [LoanStatus.Received]: "bg-warning text-dark",
        [LoanStatus.Repaid]: "bg-success text-white",
    };

    const localLoanStatus = [
        { value: LoanStatus.Requested, label: "Requested" },
        { value: LoanStatus.Approved, label: "Approved" },
        { value: LoanStatus.BankApproved, label: "Bank Approved" },
        { value: LoanStatus.Rejected, label: "Rejected" },
        { value: LoanStatus.BankReject, label: "Bank Rejected" },
        { value: LoanStatus.Cancelled, label: "Cancelled" },
        { value: LoanStatus.Received, label: "Received" },
        { value: LoanStatus.Repaid, label: "Repaid" },
    ];

    const [loanDetail, setLoanDetail] = useState<any>(null);
    const [inputs, setInputs] = useState<{
        selectedBusinessPlan: number,
        loanAmount: number,
        paymentPeriod: number
    }>({
        selectedBusinessPlan: 0,
        loanAmount: 100000,
        paymentPeriod: 1
    });

    useEffect(() => {

        if(params.id) {
            let temp_loan = localState.Loans.find((ln: any) => (ln.id == params.id));

            if(!temp_loan) {
                setAlert("loan Not found!", "error");
                return;
            }
            if(!temp_loan.company_id) {
                temp_loan.company_id = undefined;
            }
            setLoanDetail(temp_loan);
        }

    }, [])

    const inputsOnChange = (name: string, value: any) => {
        setInputs(inp => ({...inputs, [name]: value}));
    }

    const changeState = async (state: string) => {

        setTimeout(() => { setWaiting(true); }, 2);
        try {

            let result = await MainAPI.update(cookies.login_token, "loan", { ...loanDetail, status: state });
            setLoanDetail((ld: any) => ({...ld, status: state}));

            let temp_loans = localState.Loans.filter((lns: any) => (lns.id != loanDetail.id));

            setLocalState({...localState, Loans: [...temp_loans, {...loanDetail, status: state}]});
            setAlert(" Loan state updated ", "error");

        } catch(error: any) {
            setAlert(error.message, "error");
        }
        setTimeout(() => { setWaiting(false); }, 2);

    }

    // id    6470 9630 4879
    // alias 2487 2698 1374 2015

    return loanDetail ? (
        <div className="w-100 px-4 fade_in">
            <div className="d-flex justify-content-between my-3">
                <div className="rounded-5 p-3 custom-shadow" style={{ background: 'transparent', border: 'none', outline: 'none' }}>
                    <img src={`${props.baseURL}file/${loanDetail.bank_loan_bankTobank.image}`} height="70" alt="" className="rounded-4" />
                </div>
                <div className="mt-2">
                    <h2 className="mb-2 text-end">{loanDetail.bank_loan_bankTobank.name}</h2>
                    <p className="text-end color">Works with Ebidir</p>
                </div>
            </div>

            <div className={`px-3 py-4 ${state_colors[loanDetail.status]} mb-3 rounded-4`}>
                <h1 className="mb-0 fs-2">{Utils.getFromArray("value", loanDetail.status, "label", localLoanStatus)}</h1>
                <div className="card-subtitle mb-4 fs-6 w-100">Interest rate <b>{(loanDetail.interest * 100).toFixed(2)}%</b></div>

                <div className="d-flex justify-content-end">
                    {(loanDetail.status == LoanStatus.BankApproved) && (<button onClick={() => {changeState(LoanStatus.Received);}} className="btn btn-sm btn-warning me-3">Receive</button>)}
                    {(loanDetail.status == LoanStatus.Requested) && (<button onClick={() => {changeState(LoanStatus.Cancelled);}} className="btn btn-sm btn-danger">Cancel</button>)}
                </div>
            </div>
    

            <p className="mb-2 color">Loan Detail</p>
            <div className="something w-100 rounded-4 mb-3">
                <img className="w-100 dashboard-bg-image" height="230" src="/images/bg_mask.png" alt="" />
                <div className="overlay text-white w-100">
                    <div className="d-flex px-3 pt-4">
                        <div className="col me-1">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Loan Amount
                            </h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Loan Term</h6>
                        </div>
                    </div>
                    <div className="d-flex px-3">
                        <div className="col me-1">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{Utils.moneyDisplay(loanDetail.loan_amount)} ETB</h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{loanDetail.repayment_period} months</h6>
                        </div>
                    </div>
                    <div className="d-flex px-3 pt-4">
                        <div className="col me-1">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Interest</h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Total Due</h6>
                        </div>
                    </div>
                    <div className="d-flex px-3">
                        <div className="col me-1">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{(loanDetail.interest * 100).toFixed(2)}%</h6>
                        </div>
                        <div className="col ps-4">
                            <h6
                                className="text-center text-black py-2 rounded-4 bg-white"
                            >
                                {Utils.moneyDisplay((Utils.calculateMonthlyPayment(loanDetail.loan_amount, loanDetail.interest, loanDetail.repayment_period)).toFixed(2))} ETB
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-2 mb-4">
                <div className="w-100 bg-white rounded-5 d-flex justify-content-start py-3 px-3 fs-4 custom-shadow mb-3" >
                    <div className="color pe-2 me-2 text-end" style={{width: "30%", borderRight: "2px solid orange", fontSize: "15px"}}>
                        Monthly
                    </div>
                    <span style={{fontSize: "18px"}} >{Utils.moneyDisplay(loanDetail.monthly_payment)}ETB</span>
                </div>
                <div className="w-100 bg-white rounded-5 d-flex justify-content-start py-3 px-3 fs-4 custom-shadow mb-3" >
                    <div className="color pe-2 me-2 text-end" style={{width: "30%", borderRight: "2px solid orange", fontSize: "15px"}}>
                        Paid
                    </div>
                    <span style={{fontSize: "18px"}}>{Utils.moneyDisplay(loanDetail.paid_amount)}ETB</span>
                </div>
                <div className="w-100 bg-white rounded-5 d-flex justify-content-start py-3 px-3 fs-4 custom-shadow mb-3" >
                    <div className="color pe-2 me-2 text-end" style={{width: "30%", borderRight: "2px solid orange", fontSize: "15px"}}>
                        Unpaid
                    </div>
                    <span style={{fontSize: "18px"}}>{Utils.moneyDisplay(loanDetail.unpaid_amount)}ETB</span>
                </div>
                <div className="w-100 bg-white rounded-5 d-flex justify-content-between align-items-center py-2 px-3 fs-4 custom-shadow mb-3" 
                    onClick={() => {navigate(`/repayments/${loanDetail.id}`)}}
                >
                    <div className="color" style={{fontSize: "18px"}}>
                        Repayments
                    </div>
                    <span className="ms-2"><ArrowForwardIcon className="color" sx={{fontSize: 40}} /></span>
                </div>
            </div>

        </div>

    ) : (
    <div className="card slide_bottom shadow-sm mt-3 mx-3">
        <div className="card-body">
            <h5 className="card-title text-center">loan not found</h5>
        </div>
    </div>)
}

export default LoanDetail;
