import React, { useContext, useEffect, useState } from "react";
import Utils from "../Models/Utils";
import AuthContext from "../Contexts/AuthContext";
import { props } from "../APIs/api";
import { useNavigate } from "react-router-dom";
import LoanStatus from "../Enums/LoanStatus";
import AlertContext from "../Contexts/AlertContext";
import MainAPI from "../APIs/MainAPI";
import UserRoles from "../Enums/UserRoles";
import EmptyMobile from "../Components/Extra/EmptyMobile";
import JobStatus from "../Enums/JobStatus";
import EconomicInfo from "./EconomicInfo";
import CollateralType from "../Enums/CollateralType";

function RequestLoanDetail() {

    const {loanRequestForm, localState, setLocalState, cookies, loggedUser} = useContext(AuthContext);
    const {setWaiting, setAlert} = useContext(AlertContext);

    const navigate = useNavigate();
    const localJobStatus = [
        { value: JobStatus.Employed, label: "Employed" },
        { value: JobStatus.SelfEmployed, label: "Self Employed" },
        { value: JobStatus.Unemployed, label: "Unemployed" }
    ];

    const localCollateralTypes = [
        { value: CollateralType.Building, label: "Building" },
        { value: CollateralType.Vehicle, label: "Vehichle" },
        { value: CollateralType.Both, label: "Building and Vehichle" }
    ];

    const [economicData, setEconomicData] = useState<any>(null);
    const [inputs, setInputs] = useState<any>({

        id: 0,
        user_id: 0,
        bank: 0,
        loan_amount: 0,
        loan_reason: "",
        repayment_period: 1,
        collateral_type: "",
        job_status: "",
        business_plan_id: 0,
        score: 0,
        status: LoanStatus.Requested,

        interest: 0,
        loan_type: 0,
        monthly_payment: 0,
        paid_amount: 0,
        unpaid_amount: 0
    });

    const inputsOnChange = (name: string, value: any) => {

        let temp_input: any = {...inputs};
        
        if(name == "loan_amount") {
            temp_input[name] = parseFloat(value);
        }else if(name == "repayment_period" || name == "loan_type") {
            temp_input[name] = parseInt(value);
        } else {
            temp_input[name] = value;
        }

        temp_input.monthly_payment = parseFloat((Utils.calculateMonthlyPayment(temp_input.loan_amount, inputs.interest, temp_input.repayment_period)).toFixed(2));
        temp_input.idr = parseFloat((temp_input.monthly_payment / economicData.total_income).toFixed(2));
        
        console.log(temp_input);
        setInputs((inp: any) => ({...temp_input}));

    }

    useEffect(() => {

        if(!loanRequestForm.bank) {
            navigate("/select_bank");
        } else if(!loanRequestForm.loanType) {
            navigate("/select_type");
        }

        let temp_dt: any = {
            ...inputs,
            interest: loanRequestForm?.loanType.rate,
            loan_type: loanRequestForm?.loanType.name,
            user_id: loggedUser.Id,
            bank: loanRequestForm?.bank.id
        };

        if(loggedUser.Roles.includes(UserRoles.COMPANY)){
            temp_dt.company_id = loggedUser.companyId;
            temp_dt.job_status = JobStatus.Employed;
        }

        if(localState.EconomicInfo.length > 0) {
            console.log("economic info ", localState.EconomicInfo[0]);
            setEconomicData(localState.EconomicInfo[0]);
        }

        setInputs((inp: any) => ({
            ...temp_dt
        }));

    }, []);

    const submitForm = async (event: any) => {

        setTimeout(() => {setWaiting(true);}, 3);

        try {

            let temp_data = { ...inputs };

            if(temp_data.job_status == "") {
                temp_data.job_status = undefined;
            }

            let result =  await MainAPI.createNew(cookies.login_token, "loan", temp_data);
            setAlert(`Loan has been requested!`, "success");
            // setLocalState((ls: any) => ({...ls, ["Loans"]: [...localState.Loans, result.data]}));
            setTimeout(() => {setWaiting(false);}, 3);
            
        } catch (error: any) {
            setAlert(error.message, "error");
        }

        setTimeout(() => {setWaiting(false);}, 3);

    }

    return (loanRequestForm.bank && loanRequestForm.loanType) && (
        <div className="w-100 px-4 slide_left">
            <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                <div className="rounded-5 p-3 custom-shadow" >
                    <img src={`${props.baseURL}file/${loanRequestForm?.bank.image}`} width="90" alt="bank logo" className="rounded-4" />
                </div>
                <h3 className="card-title" style={{ color: '#293759' }}>{loanRequestForm?.loanType.name}</h3>
            </div>
            <p className="mb-2 color">Loan Detail</p>
            <div className="something w-100 mb-4 rounded-4">
                <img className="w-100 dashboard-bg-image" height="230" src="/images/bg_mask.png" alt="" />
                <div className="overlay text-white w-100">
                    <div className="d-flex px-3 pt-4">
                        <div className="col me-1">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Loan Amount</h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Loan Term</h6>
                        </div>
                    </div>
                    <div className="d-flex px-3">
                        <div className="col me-1">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{Utils.moneyDisplay(inputs.loan_amount)}</h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{inputs.repayment_period} months</h6>
                        </div>
                    </div>
                    <div className="d-flex px-3 pt-4">
                        <div className="col me-1">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Interest</h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center py-2 rounded-4 color" style={{ background: 'var(--main-bg)' }}>Monthly Due</h6>
                        </div>
                    </div>
                    <div className="d-flex px-3">
                        <div className="col me-1">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{(loanRequestForm?.loanType.rate * 100).toFixed(2)}%</h6>
                        </div>
                        <div className="col ps-4">
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{Utils.moneyDisplay(inputs.monthly_payment)}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <p className="mb-2 color">Loan Information</p>
            <div className="card bg-white mb-4 custom-shadow rounded-4">
                <div className=" card-body fs-4 ">
                    
                    <div className="mb-1 d-flex justify-content-between" style={{fontSize: "13px"}}>
                        <span className="color">Loan Amount</span>
                        <span className="text-dark">{Utils.moneyDisplay(inputs.loan_amount)}</span>
                    </div>
                    <input
                        type="range"
                        className="form-range color form-range-dark mb-2"
                        value={inputs.loan_amount}
                        min="100000"
                        max="2000000"
                        step="1"
                        id="customRange3"
                        onChange={(event: any) => {inputsOnChange("loan_amount", event.target.value)}}
                    />
                    <div className="mb-1 d-flex justify-content-between" style={{fontSize: "13px"}}>
                        <span className="color">Payment Period</span>
                        <span className="text-dark">{inputs.repayment_period}</span>
                    </div>
                    <input
                        type="range"
                        className="form-range color form-range-dark"
                        value={inputs.repayment_period}
                        min="1"
                        max="120"
                        step="1"
                        id="customRange3"
                        onChange={(event: any) => {inputsOnChange("repayment_period", event.target.value)}}
                    />
                    {/* <button className="fs-6 color" style={{ background: 'transparent', border: 'none', outline: 'none' }}>Update</button> */}
                </div>

            </div>

            <p className="mb-2 color">Select Business Plan</p>
            <div className="mb-4">
                {
                    (localState.BusinessPlan.length > 0) ? (
                        localState.BusinessPlan.map((bp: any) => (
                            <div
                                className="w-100 bg-white rounded-4 d-flex justify-content-start align-items-start py-3 px-3 fs-4 custom-shadow mb-3"
                                style={{cursor: "pointer"}}
                                onClick={() => {inputsOnChange("business_plan_id", bp.id)}}
                            >
                                <div style={{width: "20%"}}>
                                    <input className="form-check-input mt-0 me-3" type="checkbox" checked={inputs.business_plan_id == bp.id} aria-label="Checkbox for following text input" />
                                </div>
                                <p className="w-100 mb-0" style={{fontSize: "14px"}}>
                                    <strong>{bp.sector}</strong><br />
                                    {bp.description}
                                </p>
                            </div>
                        ))
                    ) : (<EmptyMobile message="No Business plan defined!" />)
                }
            </div>

            {
                !loggedUser.Roles.includes(UserRoles.COMPANY) && (
                    <div className="mb-3">
                        <label className="mb-2 color">Job Status</label>
                        <select
                            className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" 
                            value={inputs.job_status}
                            onChange={(event: any) => {inputsOnChange("job_status", event.target.value)}}
                        >
                            <option value="">None</option>
                            {
                                localJobStatus.map(el => (
                                    <option selected={el.value == inputs.job_status} value={el.value}>{el.label}</option>
                                ))
                            }
                        </select>
                    </div>
                )
            }

            <div className="mb-3">
                <label className="mb-2 color">Collateral Types</label>
                <select
                    className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" 
                    value={inputs.collateral_type}
                    onChange={(event: any) => {inputsOnChange("collateral_type", event.target.value)}}
                >
                    <option value="">None</option>
                    {
                        localCollateralTypes.map(el => (
                            <option selected={el.value == inputs.collateral_type} value={el.value}>{el.label}</option>
                        ))
                    }
                </select>
            </div>

            <div className="mb-4">
                <label className="mb-2 color">Reason For Loan</label>
                <textarea 
                    rows={6} 
                    className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" 
                    value={inputs.loan_reason} 
                    onChange={(event: any) => {inputsOnChange("loan_reason", event.target.value)}} 
                    placeholder="Write your reason for loan here..."
                />
            </div>

            <button className="w-100 rounded-5 p-2 fs-4 button btn mb-2" onClick={submitForm}>Send Application</button>
        </div>

    )
}

export default RequestLoanDetail;
