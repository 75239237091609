import React, { useContext, useEffect, useState } from 'react';
import TopNav from '../Components/NavBars/TopNav';
import AuthContext from '../Contexts/AuthContext';
import AlertContext from '../Contexts/AlertContext';
import MainAPI from '../APIs/MainAPI';
import { useNavigate } from "react-router-dom";
import UserRoles from '../Enums/UserRoles';
import CollateralType from '../Enums/CollateralType';
import JobStatus from '../Enums/JobStatus';

const RequetLoan = () => {

    const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
    const { loggedUser, cookies, localData } = useContext(AuthContext);

    const [currentStep, setCurrentStep] = useState(1);
    const [ListOFBanks, setListOFBanks] = useState<any[]>([])
    const [ListOfBussinessPlan, setListOfBussinessPlan] = useState<any[]>([])

    const [companyId, setCompanyId] = useState<any[]>([])

    const sectors = ['Technology', 'Finance', 'Healthcare', 'Retail'];
    const subsectors = ['Technology', 'Finance', 'Healthcare', 'Retail'];
    const collateralTypes = [
        { value: CollateralType.Building, label: "Building" },
        { value: CollateralType.Vehicle, label: "Vehichle" },
        { value: CollateralType.Both, label: "Building and Vehichle" }
    ];
    const jobStatuses = [
        { value: JobStatus.Employed, label: "Employed" },
        { value: JobStatus.SelfEmployed, label: "Self Employed" },
        { value: JobStatus.Unemployed, label: "Unemployed" }
    ];
    //form data
    const [formBank, setformBank] = useState('')
    const [formBusinessplan, setformBusinessplan] = useState('')
    const [formLoanReason, setformLoanReason] = useState('')
    const [formData, setFormData] = useState({
        loan_amount: "",
        repayment_period: "",
        collateral_type: "",
        job_status: "",
    });


    const navigate = useNavigate();
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setTimeout(() => { setWaiting(true) }, 1);
        try {

            var token = cookies.login_token
            var newdata = {}
            if (loggedUser.Roles.includes(UserRoles.PERSONAL)) {
                newdata = {

                    "user_id": loggedUser.Id,
                    "bank": parseInt(formBank),
                    "loan_amount": parseInt(formData.loan_amount),
                    "repayment_period": parseInt(formData.repayment_period),
                    "collateral_type": "building",
                    "job_status": formData.job_status,
                    "loan_reason": formLoanReason,
                    "business_plan_id": parseInt(formBusinessplan)
                }
            } else if (loggedUser.Roles.includes(UserRoles.COMPANY)) {

                newdata = {
                    "company_id": companyId,
                    "user_id": loggedUser.Id,
                    "bank": parseInt(formBank),
                    "loan_amount": parseInt(formData.loan_amount),
                    "repayment_period": parseInt(formData.repayment_period),
                    "collateral_type": "building",
                    "job_status": formData.job_status,
                    "loan_reason": formLoanReason,
                    "business_plan_id": parseInt(formBusinessplan)
                }
            }

            let response = await MainAPI.createNew(token, "loan", newdata);


            setWaiting(false);
            setAlert("Loan Request successful", "success");
            navigate("/list/tbl_loan");

        } catch (error: any) {
            setWaiting(false);
            setAlert(error.message, "error");
        }

    }

    useEffect(() => {
        async function getListOfBanks() {
            try {

                var token = cookies.login_token
                let response = await MainAPI.getAll(token, "bank", 1, 100);
                var loans = response.Items
                setListOFBanks(loans)


            } catch (error: any) {

            }
        }
        getListOfBanks()
        async function getListOfBusinessPlans() {
            try {

                var token = cookies.login_token
                var condition = {
                    "user_id": {
                        "operator": "equal",
                        "value": loggedUser.Id,
                        "type": "number"
                    }
                }
                let response = await MainAPI.getAll(token, "businessplan", 1, 100, condition);
                var BussinessPlan = response.Items
                setListOfBussinessPlan(BussinessPlan)


            } catch (error: any) {

            }
        }
        getListOfBusinessPlans()
        async function getcompanyid() {
            try {

                var condition = {
                    "user_id": {
                        "operator": "equal",
                        "value": loggedUser.Id,
                        "type": "number"
                    }
                }
                var token = cookies.login_token
                let response = await MainAPI.getAll(token, "company", 1, 100, condition);

                if(response.Items.length > 0) {
                    setCompanyId(response.Items[0].id)
                }

            } catch (error: any) {
                setWaiting(false);
                setAlert(error.message, "error");
            }
        }
        getcompanyid()
    }, [])
    const renderFormStep = (step: number) => { // Specify step type as number
        switch (step) {
            case 1:
                return (

                    <div className="form-step active">
                        <h2>Step 1: Select Bank</h2>
                        {/* Add your form fields here */}
                        <div className="d-flex justify-content-center mb-4">
                            <div className="col-4">

                                <label >Loan Provider</label>
                                <select className="form-control form-control-lg form-control form-control-sm-sm " value={formBank}
                                    onChange={(e) => setformBank(e.target.value)}>
                                    <option key="" value="">
                                        Select Provider
                                    </option>
                                    {ListOFBanks.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-5"> {/* Center buttons */}
                            <button type="button" className="btn btn-primary" onClick={handleNext}>
                                Next
                            </button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="form-step">
                        <h2>Step 2: Business Plan</h2>
                        {/* Add your form fields here */}
                        <div className="d-flex justify-content-center m-5">
                            <div className="col-4">

                                <label >Select Bussiness plan</label>
                                <select className="form-control form-control-lg form-control form-control-sm-sm " value={formBusinessplan}
                                    onChange={(e) => setformBusinessplan(e.target.value)}>
                                    <option key="" value="">
                                        Select Business Plan
                                    </option>
                                    {ListOfBussinessPlan.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.sector + " - " + option.score}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* ... other form fields ... */}

                        <div className="d-flex justify-content-center mt-5"> {/* Center buttons */}
                            <button type="button" className="btn btn-secondary me-3" onClick={handlePrev} style={{ marginRight: "10px" }}>
                                Previous
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleNext} style={{ marginRight: "10px" }}>
                                Next
                            </button>

                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="form-step">
                        <h2>Step 3: Loan Detail</h2>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="job_status" className="form-label">
                                    Job Status:
                                </label>
                                <select
                                    id="job_status"
                                    name="job_status"
                                    value={formData.job_status}
                                    onChange={handleChange}
                                    className="form-select"
                                >
                                    <option key="" value="">
                                        Select Job Status
                                    </option>
                                    {jobStatuses.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="loan_amount" className="form-label">
                                    Loan Amount:
                                </label>
                                <input
                                    type="number"
                                    id="loan_amount"
                                    name="loan_amount"
                                    value={formData.loan_amount}
                                    onChange={handleChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="repayment_period" className="form-label">
                                    Repayment Period (Months):
                                </label>
                                <input
                                    type="number"
                                    id="repayment_period"
                                    name="repayment_period"
                                    value={formData.repayment_period}
                                    onChange={handleChange}
                                    className="form-control"
                                />
                            </div>
                            {/* <div className="col-md-6 mb-3">
                                <label htmlFor="collateral_type" className="form-label">
                                    Collateral Type:
                                </label>
                                <select
                                    id="collateral_type"
                                    name="collateral_type"
                                    value={formData.collateral_type}
                                    onChange={handleChange}
                                    className="form-select"
                                >
                                    {collateralTypes.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                            <div className="col-12 mb-3">
                                <label htmlFor="loan_reason" className="form-label">
                                    Loan Reason:
                                </label>
                                <textarea
                                    style={{ minHeight: "100px" }}
                                    id="loan_reason"
                                    name="loan_reason"
                                    value={formLoanReason}
                                    onChange={(e) => setformLoanReason(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {/* Add your confirmation content here */}
                        <div className="d-flex justify-content-center mt-5"> {/* Center buttons */}
                            <button type="button" className="btn btn-secondary me-3" onClick={handlePrev}>
                                Previous
                            </button>
                            <button type="submit" className="btn btn-success " onClick={handleSubmit} style={{ marginRight: "10px" }}>
                                Submit
                            </button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const progressBarStyle = { width: `${(currentStep - 1) * 50}%` };

    return (
        <div className="w-100" >

            <TopNav />
            <div className="w-100 d-flex justify-content-between mb-2 px-3 py-1">

                <div className="container mt-3">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <div className="d-flex justify-content-center mb-3">
                                <img src="/images/ebidir_logo_w_name.png" alt="logo" style={{width: "200px"}} />
                            </div>
                            <div className="progress mb-4">
                                <div className="progress-bar" role="progressbar" style={progressBarStyle} aria-valuenow={currentStep} >
                                    Step {currentStep}
                                </div>
                            </div>

                            <div id="multi-step-form" className="multi-step-form">
                                {renderFormStep(currentStep)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequetLoan;
