import { IconButton } from "@mui/material";
import React, { useContext } from "react";
import AlertContext from "../Contexts/AlertContext";
import { Menu, Person, Send, SupportAgent } from "@mui/icons-material";
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';

function ChatPage() {

    const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);

    
    return (
        <div className="d-flex w-100 h-100" style={{position: "relative"}}>
            <div className="col-3 h-100 border-end" style={{display: "flex", flexDirection: "column"}}>
                <div className=" d-flex justify-content-start p-3 align-items-center" style={{height: "10%"}}>
                    <div className="me-3">
                        <IconButton onClick={() => { setMenu(true) }}>
                            <Menu sx={{fontSize: 30}} />
                        </IconButton>
                    </div>
                    <h5 className="card-title">All Chats</h5>
                </div>
                <div className="w-100 h-100 px-2">

                    <div className="card shadow-sm mb-4">
                        <div className="card-body d-flex align-items-center">
                            <Person sx={{fontSize: 50}}/>
                            <div className="w-100 ms-3">
                                <h5 className="card-title mb-0">User Name</h5>
                                <span className="card-subtitle text-muted mb-0">customer</span>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                        <div className="card-body d-flex align-items-center">
                            <Person sx={{fontSize: 50}}/>
                            <div className="w-100 ms-3">
                                <h5 className="card-title mb-0">User Name</h5>
                                <span className="card-subtitle text-muted mb-0">customer</span>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                        <div className="card-body d-flex align-items-center">
                            <Person sx={{fontSize: 50}}/>
                            <div className="w-100 ms-3">
                                <h5 className="card-title mb-0">User Name</h5>
                                <span className="card-subtitle text-muted mb-0">customer</span>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                        <div className="card-body d-flex align-items-center">
                            <Person sx={{fontSize: 50}}/>
                            <div className="w-100 ms-3">
                                <h5 className="card-title mb-0">User Name</h5>
                                <span className="card-subtitle text-muted mb-0">customer</span>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm mb-4">
                        <div className="card-body d-flex align-items-center">
                            <Person sx={{fontSize: 50}}/>
                            <div className="w-100 ms-3">
                                <h5 className="card-title mb-0">User Name</h5>
                                <span className="card-subtitle text-muted mb-0">customer</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="col h-100 chat_container border-end">
                <div className="border-bottom bg-white p-3 d-flex justify-content-start align-items-center">
                    <div className="me-3">
                        <IconButton onClick={() => {  }}>
                            <Person sx={{fontSize: 30}} />
                        </IconButton>
                    </div>
                    <h5 className="card-title">Abnet Kebede</h5>
                </div>
                <div className="h-100 border-bottom" style={{overflow: "hidden auto"}}>

                <div className="px-3 py-3 d-flex justify-content-end" style={{width: "80%", position: "relative", left: "20%"}} >
                    <div className="chat_message_input_container py-3 px-4  shadow-sm" style={{borderBottomRightRadius: 0}}>
                        <p className="lead">hello there</p>
                    </div>
                    <div className="rounded-circle d-flex bg-dark ms-3 mt-auto" style={{width: "80px", height: "70px"}}>
                        <Person sx={{margin: "auto", fontSize: 30, color: "white"}} />
                    </div>
                </div>
                <div className="px-3 py-3 d-flex justify-content-start" style={{width: "80%"}} >
                    <div className="rounded-circle d-flex bg-dark me-3 mt-auto" style={{width: "80px", height: "70px"}}>
                        <SupportAgent sx={{margin: "auto", fontSize: 30, color: "white"}} />
                    </div>
                    <div className="chat_message_input_container py-3 px-4  shadow-sm" style={{borderBottomLeftRadius: 0}}>
                        <p className="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae officiis autem distinctio dignissimos architecto vel voluptatem ipsum consectetur blanditiis, accusamus, amet id quia voluptate, vitae sunt asperiores provident reprehenderit veniam!</p>
                    </div>
                </div>

                </div>
                <div className="px-3 py-3 w-100 d-flex justify-content-end" >
                    <div className="chat_message_input_container py-3 px-4 shadow">
                        <textarea name="message" rows={1} />
                    </div>
                    <div className="rounded-circle d-flex bg-dark ms-3" style={{width: "80px", height: "70px"}}>
                        <Send sx={{margin: "auto", fontSize: 30, color: "white"}} />
                    </div>
                </div>
            </div>
            <div className="col-3 h-100"></div>
        </div>
    );
};

export default ChatPage;