import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AuthContext from "../Contexts/AuthContext";

function Setting() {

    const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
    const {loggedUser, removeCookie} = useContext(AuthContext);

    const navigate = useNavigate();

    return (
        <div className="w-100 px-4 bg-white slide_bottom" >
            <div className="w-100 d-flex justify-content-center mt-5 mb-2" >
                <div className="rounded-circle" style={{width: 115, height: 115, overflow: "hidden"}}>
                    <img 
                        src="/images/user_icon_image_no_bg.png"
                        alt="e-bidir logo"
                        width="115"
                        style={{position: "relative", top: "50%", transform: "translateY(-50%)"}}
                    />
                </div>
            </div>
            <h5 className="text-center mb-0 h-50">{loggedUser.FullName}</h5>
            <div className="text-center mb-5 lead">{loggedUser.Roles[0]}</div>

            <div className="mb-5">
                <button className="w-100 rounded-5 py-2 bg-white fs-4 custom-shadow" onClick={() => {setMenu(false); navigate("/change_password")}} >Change Password</button>
            </div>
            <div className="mb-5">
                <button className="w-100 rounded-5 py-2 bg-white fs-4 custom-shadow" onClick={() => {setMenu(false); navigate("/support")}} >Support</button>
            </div>
            <div className="mb-5">
                <button className="w-100 rounded-5 py-2 bg-white fs-4 custom-shadow" onClick={() => {setMenu(false); navigate("/faq")}} >FAQ</button>
            </div>
            <div className="mb-5">
                <button className="w-100 rounded-5 py-2 bg-white fs-4 custom-shadow" onClick={() => {
                    setMenu(false);
                    removeCookie("login_token");
                    setTimeout(() => {
                        window.location.href = window.location.origin;
                    }, 2000);
                }} >Sign Out</button>
            </div>
        </div>
    )
}

export default Setting;