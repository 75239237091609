
import FieldTypes from "../Enums/FiedTypes";
import Operators from "../Enums/Operators";
import IPagination from "../Intefaces/IPagination";
import Company from "../Models/Company";
import User from "../Models/User";
import Utils from "../Models/Utils";
import { authFileRequest, Authorized } from "./api";

class MainAPI {

    public static async getAll(token: string, tableName: string, pageNumber: number, pageSize: number, condition?: any, sort?: any): Promise<IPagination<any>> {

        try {
            // let query = condition ? `?${Utils.objectToQueryString(condition)}` : "";
            // let query = "";
            return await Authorized(token).bodyRequest("post", `crud/getlist/${tableName}/${pageNumber}/${pageSize}`, {condition: condition ?? {}, sort: sort ?? {}});
        } catch (error: any) {
            console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }

    public static async getAllRelated(token: string, tableName: string, pageNumber: number, pageSize: number, condition?: any, sort?: any): Promise<IPagination<any>> {

        try {
            // let query = condition ? `?${Utils.objectToQueryString(condition)}` : "";
            // let query = "";
            return await Authorized(token).bodyRequest("post", `crud/getlist/${tableName}/${pageNumber}/${pageSize}?type=related`, {condition: condition ?? {}, sort: sort ?? {}});
        } catch (error: any) {
            console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }
    
    public static async loadAttachments(token: string, table: string, record_id: string): Promise<IPagination<any>> {

        try {
            // let query = condition ? `?${Utils.objectToQueryString(condition)}` : "";
            // let query = "";
            return await Authorized(token).bodyRequest("post", `crud/getlist/attachment/1/100`, {
                condition: {
                    "record": {operator: Operators.IS, type: FieldTypes.TEXT, value: record_id},
                    "table": {operator: Operators.IS, type: FieldTypes.TEXT, value: table}
                },
                sort: {}
            });
        } catch (error: any) {
            // console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }

    public static async getSingle(token: string, tableName: string, id: number): Promise<any> {
        try {
            return await Authorized(token).bodyRequest("get", `crud/getform/${tableName}/${id}`);
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    public static async createNew(token: string, table: string, new_data: any): Promise<any> {
        return await Authorized(token).bodyRequest("post", "crud/create", {tableName: table, data: new_data});
    }

    public static async update(token: string, tableName: string, new_data: any): Promise<any> {
        return await Authorized(token).bodyRequest("put", "crud/update", {table: tableName, data: new_data});
        // try {
        // } catch (error: any) {
        //     console.log(error.message);
        //     return null;
        // }
    }

    public static async forSelectBox(token: string, table: string, as_id: string, as_label: string, condition?: any) {
        try {
            return await Authorized(token).bodyRequest("get", `crud/selectbox/${table}/${as_id}/${as_label}`);
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    public static async addAttachment(token: string, table: string, record: number, attachment: {file: any, name: string}){
        return await authFileRequest(token, "post", "file/upload", {table, record: `${record}`, ...attachment});
    }

}

export default MainAPI;