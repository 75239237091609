import React, { useContext, useEffect, useRef, useState } from "react";
import LoanAdComponent from "../Components/LoanShowCase";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Contexts/AuthContext";
import Utils from "../Models/Utils";
import { props } from "../APIs/api";
import EmptyMobile from "../Components/Extra/EmptyMobile";
import UserRoles from "../Enums/UserRoles";
import Chart from 'chart.js/auto';
import LoanStatus from "../Enums/LoanStatus";

function DashboardScreen() {

    const {localState, loggedUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const [score, setScore] = useState<number>(0);
    const [totalLoan, setTotalLoan] = useState<number>(0);
    const [loansCount, setLoansCount] = useState<{
        requested: number,
        approved: number,
        rejected: number,
        received: number,
        repaid: number,
    }>({
        requested: 0,
        approved: 0,
        rejected: 0,
        received: 0,
        repaid: 0
    });
    const chart_ref = useRef(null);
    const chart_obj = useRef<any>(null);

    useEffect(() => {

        let temp_count = { ...loansCount }
        localState.Loans.forEach((ln: any) => {
            if(ln.status == LoanStatus.Approved) {
                temp_count.approved += 1;
            }else if(ln.status == LoanStatus.Requested) {
                temp_count.requested += 1;
            }else if(ln.status == LoanStatus.Rejected) {
                temp_count.rejected += 1;
            }
        });

        chartLoader(temp_count);
        setLoansCount(temp_count);

    }, []);

    useEffect(() => {

        let score = 0;
        let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
        if(localState.PersonalInfo.length > 0 || localState.Companies.length > 0) {
            score += !is_company ? parseInt(localState.PersonalInfo[0].score) : parseInt(localState.Companies[0].score ?? 0);
        }
        
        if(localState.EconomicInfo.length > 0) {
            score += parseInt(localState.EconomicInfo[0].score);
        }
        
        setScore(score);

        let total_loan = 0;
        localState.Loans.forEach((ln: any) => {
            total_loan += parseInt(ln.unpaid_amount);
        });

        setTotalLoan(total_loan);

    }, [localState]);

    useEffect(()=>{

        if(chart_obj.current.data) {
            chart_obj.current.data.datasets[0].data = [loansCount.approved, loansCount.requested, loansCount.rejected];
            chart_obj.current.update();
        }

        // const data = {
        //     labels: [
        //         'Approved',
        //         'Pending',
        //         'Declined'
        //     ],
        //     datasets: [{
        //         label: 'Summery',
        //         data: [loansCount.approved, loansCount.requested, loansCount.rejected],
        //         backgroundColor: [
        //             'rgb(255, 99, 132)',
        //             'rgb(54, 162, 235)',
        //             'rgb(255, 205, 86)'
        //         ],
        //         hoverOffset: 4
        //     }]
        // };
        // if(loansCount.approved!=0.1)
        //     {chartLoader(data);}
    },[loansCount])

    const chartLoader = (data: any) => {

		if (chart_ref.current) {

			chart_obj.current = new Chart(chart_ref.current, {
				type: 'doughnut',
				data: {
                    labels: [
                        'Approved',
                        'Pending',
                        'Declined'
                    ],
                    datasets: [{
                        label: 'Summery',
                        data: [data.approved, data.requested, data.rejected],
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4
                    }]
                },
				options: {
					// onClick: (e) => {
					// const canvasPosition = getRelativePosition(e, chart);

					// // Substitute the appropriate scale IDs
					// const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
					// const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
					// }
				}
			});
		} else {
			console.log("element not found");
		}
	}

    return (
        <div className="w-100 px-4 slide_top">

            <div className="something w-100 mt-3 mb-3 rounded-4">
                <img className="w-100 dashboard-bg-image" src="/images/dashboard_mask.png" alt="" />
                <div className="overlay text-white w-100">
                    <div className="d-flex px-3 pt-4">
                        <div className="col px-2" style={{ borderRight: "2px solid white"}} >
                            <h6 className="text-center py-2 rounded-4 text-white" style={{ background: 'var(--main-bg)' }}>Creadit Score</h6>
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{score}</h6>
                        </div>
                        <div className="col px-2">
                            <h6 className="text-center py-2 rounded-4 text-white" style={{ background: 'var(--main-bg)' }}>Total Loan</h6>
                            <h6 className="text-center text-black py-2 rounded-4 bg-white">{Utils.moneyDisplay(totalLoan)}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-title mb-3">Personalized Loan</div>
            <div className="card w-100 shadow-sm rounded-4 mb-3">
                <div className="card-body p-4">

                    <canvas ref={chart_ref} className="w-100" />
                    {/* <button className="btn text-center text-black py-2 rounded-5 bg-white mb-3 w-100 border" style={{fontSize: "20px"}}>Approved</button> */}
                </div>
            </div>

            <div className="card-title">Personalized Loan</div>
            <div className="w-100 mb-3" style={{overflow: "auto hidden"}}>
                <div className="d-flex py-3" style={{width: "max-content"}}>
                    {
                        localState.LoanTypes.map((lt: any) => (
                            <LoanAdComponent loanType={lt} />
                        ))
                    }
                </div>
            </div>

            <div className="card-title mb-2">Loan History</div>
            <div className="card w-100 shadow-sm rounded-4 border mb-3">
                <div className="card-body p-4">
                    {
                        localState.Loans.length > 0 ? (
                            localState.Loans.map((ln: any) => (
                                <div
                                    key={`loan_${ln.id}`}
                                    className="px-3 rounded-5 bg-white mb-3 w-100 border d-flex align-items-center"
                                    style={{cursor: "pointer"}}
                                    onClick={() => {navigate(`/loan_detail/${ln.id}`);}}
                                >
                                    <div className="me-3 py-3">
                                        <img src={`${props.baseURL}file/${ln.bank_loan_bankTobank.image}`} alt="bank logo" style={{width: "35px"}} />
                                    </div>
                                    <div className="w-100">
                                        <div className="color" style={{fontSize: "15px"}}>Student Loan</div>
                                        <div className="text-dark" style={{fontSize: "16px"}}>{Utils.moneyDisplay(ln.loan_amount)}</div>
                                    </div>
                                    <div className="py-3">
                                        <button className="btn btn-sm btn-link color" style={{fontSize: "13px"}}>View</button>
                                    </div>
                                </div>
                            ))
                        ) : (<EmptyMobile message="Loan not yet requested!" />)
                    }
                </div>
            </div>

        </div>
    );

}

export default DashboardScreen;