import React from "react";
import Utils from "../Models/Utils";
import { props } from "../APIs/api";

function LoanAdComponent({loanType}: {loanType: {id: number, name: string, rate: number, max_amount: number, bank_id: number, bank?: any}}) {

    return (
        <div className="card rounded-4 border-1 shadow me-3" style={{position: "relative", width: "55vw"}} >
            <div className="card-body">
                <div className="d-flex align-items-center mb-2" style={{position: "relative"}}>
                    <img src={loanType.bank ? `${props.baseURL}file/${loanType.bank.image}` : "/images/Dashen Bank Logo.png"} alt="bank logo" className="rounded-4" style={{position: "relative", width: "60%"}} />
                    <div className="w-100 ms-3">
                        <div className="color lead text-center mb-2" style={{fontSize: "10px"}}>Interest Rate</div>
                        <div className="text-center text-black rounded-4 bg-white border" style={{fontSize: "20px"}}>{parseFloat((loanType.rate * 100).toFixed(2))}%</div>
                    </div>
                </div>

                <div className="color lead mb-1" style={{fontSize: "10px"}}>Loan Type</div>
                <div className="text-center text-black rounded-4 bg-white border mb-2" style={{fontSize: "12px", border: "2px solid gray"}}>{loanType.name}</div>
                
                <div className="color lead mb-1" style={{fontSize: "10px"}}>Max Amount</div>
                <div className="text-center text-black rounded-4 bg-white border" style={{fontSize: "12px", border: "2px solid gray"}}>{Utils.moneyDisplay(loanType.max_amount)}</div>

            </div>
        </div>
    );

}

export default LoanAdComponent;