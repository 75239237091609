import React from 'react';
import { useNavigate } from 'react-router-dom';

export default () => {

    const navigate = useNavigate();

    return (
        <div className='d-flex justify-content-center mt-5'>
            <div className="col"></div>
            <div className='col-sm-10 col-md-6 p-2 d-flex justify-content-center' style={{flexWrap: "wrap"}}>
                <img src="/images/page_not_found_transparent.png" className='w-100 mb-3' alt="error vector icon" />
                <button className="btn btn-lg btn-primary" onClick={() => {navigate("/")}}>Go Home</button>
            </div>
            <div className="col"></div>
        </div>
    );
}