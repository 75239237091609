import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../Contexts/AuthContext";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import AttachmentForm from "../Components/Reusables/AttachmentForm";
import AttachmentList from "../Components/Reusables/AttachmentList";
import AttachmentComponent from "../Components/Reusables/AttachmentComponent";
import Sectors from "../Enums/Sectors";
import PlanStage from "../Enums/PlanStage";
import AlertContext from "../Contexts/AlertContext";
import MainAPI from "../APIs/MainAPI";
import UserRoles from "../Enums/UserRoles";

function BusinessPlanForm() {

    const {loggedUser, localState, cookies, setLocalState} = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);

    const params = useParams<any>();
    const localSectors = [
        { value: Sectors.Agricalture, label: "Agricalture" },
        { value: Sectors.Service, label: "Service" },
        { value: Sectors.Tech, label: "Technology" },
        { value: Sectors.Manufacture, label: "Manufacturing" },
        { value: Sectors.Construction, label: "Construction" }
    ];
    const localPlanStage = [
        { value: PlanStage.Ideation, label: "Ideation Stage" },
        { value: PlanStage.Revenue, label: "Revenue MVP" },
        { value: PlanStage.PostRevenue, label: "Post Revenue" }
    ];

    const [editing, setEditing] = useState<boolean>(false);
    const [economicInfo, setEconomicInfo] = useState<any>({});

    useEffect(() => {

        let temp_plan: any = {};
        if(params.id) {
            let plan = localState.BusinessPlan.find((bp: any) => (bp.id == params.id));
            if(plan){
                temp_plan = plan;
            }
        }
        
        temp_plan.user_id = loggedUser.Id;
        if(loggedUser.Roles.includes(UserRoles.COMPANY)){
            temp_plan.company_id = loggedUser.companyId;
        }

        setEconomicInfo(temp_plan);

    }, []);

    const inputsOnChange = (event: any) => {
        let temp_inp = economicInfo;

        if(event.target.name == "monthly_revenue" || event.target.name == "stimated_cost") {
            temp_inp[event.target.name] = parseFloat(event.target.value);
        } else if(event.target.name == "opportunity_created"  || event.target.name == "business_groups") {
            temp_inp[event.target.name] = parseInt(event.target.value);
        }else {
            temp_inp[event.target.name] = event.target.value;
        }

        temp_inp.total_income = temp_inp.monthly_revenue;

        setEconomicInfo((ei: any) => ({...temp_inp}));

    }

    const submitForm = async (event: any) => {

        try {
            
            let isNew = (params.id && parseInt(params.id) == -1);
            setTimeout(() => {setEditing(false);}, 3);
            let result =  isNew ? await MainAPI.createNew(cookies.login_token, "businessplan", economicInfo) : await MainAPI.update(cookies.login_token, "businessplan", economicInfo);
            setAlert(`Personal info has been ${isNew ? "created" : "updated"} successfully`, "success");
            if(isNew) {
                setLocalState((ls: any) => ({...ls, ["BusinessPlan"]: [...ls.BusinessPlan, result.data]}));
            }

        } catch (error: any) {
            setAlert(error.message, "error");
        }

    }

    return (
        <div className="w-100 px-4 slide_left">

            <div className="d-flex mb-3 justify-content-between mt-2 align-items-center">
                <h2 className="card-title text-center mb-3 mt-2"> Business Plan </h2>

                {(params.id && parseInt(params.id) > 0) && (
                    <div className="d-flex justify-content-end">
                        <span className="p-2 shadow-sm rounded-3 bg-white" style={{height: "max-content"}}>
                            <EditIcon sx={{fontSize: "30px", display: (editing ? "none" : "")}} className="color" onClick={() => {setEditing(true);}} />
                            <CloseIcon sx={{fontSize: "30px", display: (!editing ? "none" : "")}} className="color" onClick={() => {setEditing(false);}} />
                        </span>
                    </div>
                )}
            </div>

            <div className="mb-3">
                <label className="mb-2">Sector</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (
                            <select 
                                className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow"
                                value={economicInfo.sector}
                                name="sector"
                                onChange={inputsOnChange}
                            >
                                <option value="">None</option>
                                {
                                    localSectors.map(el => (
                                        <option selected={el.value == economicInfo.sector} value={el.value}>{el.label}</option>
                                    ))
                                }
                            </select>
                        ) : (
                            <div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.sector ?? "Not Set"}</div>
                        )
                }
            </div>
            <div className="mb-3">
                <label className="mb-2">Sub Sector</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (
                            <select 
                                className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow"
                                value={economicInfo.sub_sector}
                                name="sub_sector" onChange={inputsOnChange}
                            >
                                <option value="">None</option>
                                {
                                    localSectors.map(el => (
                                        <option selected={el.value == economicInfo.sub_sector} value={el.value}>{el.label}</option>
                                    ))
                                }
                            </select>
                        ) : (
                            <div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.sub_sector ?? "Not Set"}</div>
                        )
                }
            </div>
            <div className="mb-3">
                <label className="mb-2">Current Stage</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (
                            <select 
                                className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow"
                                value={economicInfo.current_stage}
                                name="current_stage"
                                onChange={inputsOnChange}
                            >
                                <option value="">None</option>
                                {
                                    localPlanStage.map(el => (
                                        <option selected={el.value == economicInfo.current_stage} value={el.value}>{el.label}</option>
                                    ))
                                }
                            </select>
                        ) : 
                        (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.current_stage ?? "Not Set"}</div>)
                }
            </div>
            <div className="mb-3">
                <label className="mb-2">Estimated Cost</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.stimated_cost} name="stimated_cost" onChange={inputsOnChange} placeholder="Estimated Cost" />) : 
                        (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.stimated_cost ?? "Not Set"}</div>)
                }
            </div>
            <div className="mb-3">
                <label className="mb-2">Involved Business Groups</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.business_groups} name="business_groups" onChange={inputsOnChange} placeholder="Involved Business Groups" />) : 
                        (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.business_groups ?? "Not Set"}</div>)
                }
            </div>
            <div className="mb-3">
                <label className="mb-2">Job Opportunity</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.opportunity_created} name="opportunity_created" onChange={inputsOnChange} placeholder="Job Opportunity Create" />) : 
                        (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.opportunity_created ?? "Not Set"}</div>)
                }
            </div>
            <div className="mb-3">
                <label className="mb-2">Total Monthly Income</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (<input type="number" className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.monthly_revenue} name="monthly_revenue" onChange={inputsOnChange} placeholder="Total Monthly Income" />) : 
                        (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.monthly_revenue ?? "Not Set"}</div>)
                }
            </div>
            <div className="mb-4">
                <label className="mb-2">Description</label>
                {
                    (editing || (params.id && parseInt(params.id) == -1)) ? 
                        (<textarea rows={6} className="w-100 rounded-5 p-2 px-3 fs-5 custom-shadow" value={economicInfo.description} name="description" onChange={inputsOnChange} placeholder="Explain your business here..." />) : 
                        (<div className="w-100 fs-4 border rounded-3 p-2">{economicInfo.description ?? "Not Set"}</div>)
                }
            </div>

            {editing && (<button className="w-100 rounded-5 p-2 fs-4 button btn mb-4" onClick={submitForm}>Update</button>)}
            { (params.id && parseInt(params.id) == -1) && (<button className="w-100 rounded-5 p-2 fs-4 button btn mb-4" onClick={submitForm}>Create</button>)}

        </div>
    )
}
export default BusinessPlanForm;