import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import IField from "../Intefaces/IField";

export default function ({ selector, cols, rows }: { selector: (id: number) => void, cols: IField[], rows: any[] }) {

    const [columns, setColumns] = useState<IField[]>([]);
    const [records, setRecords] = useState<any[]>([]);

    useEffect(() => {
        setColumns(cols);
        setRecords(rows);
    }, [cols, rows]);

    return (
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
            <div className="col"></div>
            <div className={isMobile ? "w-100 bg-white" : "col-xlg-10 col-lg-12 bg-white"}>
                <div className="card-body" style={{ width: "100%", overflowX: "auto" }}>
                    <table className="table table-white table-striped table-hover">
                        <thead>
                            <tr>
                                <td scope="col">
                                    <input className="form-check-input" type="checkbox" title="Select all records" />
                                </td>
                                {columns.map(col => (col.visible ? (<th key={col.id} id={col.id}>{col.label}</th>) : <></>))}
                            </tr>
                        </thead>
                        <tbody>
                            {records.map(rec => (
                                <tr key={`row_${rec.id}`}>{
                                    columns.map(col => {
                                        if(col.visible){
                                            if((col.id.toLowerCase()) == "id") {
                                                return (
                                                    <><td scope="row" key={`select_col_${rec.Id}`} >
                                                        <input className="form-check-input" type="checkbox" title="select this record" />
                                                    </td>
                                                    <td key={`${col.id}_col_${rec.Id}`}>
                                                        <button className="btn btn-link btn-sm" onClick={() => { selector(rec[col.id]) }}>{rec[col.id]}</button>
                                                    </td></>
                                                );
                                            }else{
                                                return (<td scope="row" key={`${col.id}_col_${rec.Id}`}>{rec[col.id]}</td>);
                                            }
                                        }else {
                                            return (<></>);
                                        }
                                    })
                                }</tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col"></div>
        </div>
    );
}