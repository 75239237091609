import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Contexts/AuthContext";
import { props } from "../APIs/api";
// import MainAPI from "../APIs/MainAPI";
// import UserRoles from "../Enums/UserRoles";
// import FieldTypes from "../Enums/FiedTypes";
// import Operators from "../Enums/Operators";

function RequestLoan() {

    const {localState, cookies, logged_user, loanRequestForm, setLoanRequestForm} = useContext(AuthContext);

    const navigate = useNavigate();

    const [banks, setBanks] = useState<any[]>([]);

    // useEffect(() => {
    //     getBanks();
    // })

    // const getBanks = async () => {
    //     setBanks((await MainAPI.getAll(cookies.login_token, "bank", 1, 100, {})).Items);
    // }

    return (
        <div className="w-100 px-4 slide_top">
            <h3 className="mb-3 mt-5">Banks</h3>
            <p className="mb-2 color">Select the institute you want to request a loan from</p>
            <div className="d-flex flex-wrap mt-3">
                {
                    localState.Banks.map((bnk: any) => (
                        <div className="rounded-5 p-3 mb-3 mx-1 custom-shadow " style={{width: "30.5%"}} onClick={() => {
                            setLoanRequestForm((lrf: any) => ({...lrf, bank: bnk}));
                            navigate("/select_type");
                        }} >
                            <img src={`${props.baseURL}file/${bnk.image}`} width="100%" alt="bank image" className="rounded-4" />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default RequestLoan;