import React, { useContext } from "react";
import AuthContext from "../Contexts/AuthContext";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import EmptyMobile from "../Components/Extra/EmptyMobile";

function BusinessPlans() {

    const {loanRequestForm, localState} = useContext(AuthContext);

    const navigate = useNavigate();

    return (
        <div className="p-3 slide_right">
            
            <div className="d-flex mb-3 justify-content-between align-items-center">
                <h2 className="card-title text-center mb-3 mt-2"> Business Plans</h2>
                <div className="d-flex justify-content-end">
                    <span className="p-2 pe-3 shadow-sm rounded-3 bg-white" style={{height: "max-content"}} onClick={() => {navigate(`/business_plan/-1`);}}>
                        <AddIcon sx={{fontSize: "30px"}} className="color me-2" />
                        New
                    </span>
                </div>
            </div>

            {
                (localState.BusinessPlan.length > 0) ? (
                    localState.BusinessPlan.map((bp: any) => (
                        <div
                            className="w-100 bg-white rounded-4 py-3 px-3 fs-4 custom-shadow mb-3"
                            style={{cursor: "pointer"}}
                            onClick={() => {navigate(`/business_plan/${bp.id}`)}}
                        >
                            <strong className="fs-2">{bp.sector}</strong><br />
                            <span className="fs-5 mb-0">{bp.sub_sector}</span>
                            <p className="w-100 mb-0" style={{fontSize: "14px"}}>
                                {bp.description}
                            </p>
                        </div>
                    ))
                ) : (<EmptyMobile message="No business plan defined yet!" />)
            }
        </div>
    );

}

export default BusinessPlans;