import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import UserRoles from "../Enums/UserRoles";
import Utils from "../Models/Utils";

function Profile() {

    const {loggedUser, localState} = useContext(AuthContext);

    const navigate = useNavigate();

    const [componentState, setComponentState] = useState<{score: number, unpaid: number, paid: number}>({
        score: 0,
        unpaid: 0,
        paid: 0
    });

    useEffect(() => {

        let score = 0;
        if(localState.PersonalInfo.length > 0) {
            score += parseInt(localState.PersonalInfo[0].score);
        }
        
        if(localState.EconomicInfo.length > 0) {
            score += parseInt(localState.EconomicInfo[0].score);
        }

        let total_unpaid = 0;
        let total_paid = 0;
        localState.Loans.forEach((ln: any) => {
            total_unpaid += parseInt(ln.unpaid_amount);
            total_paid += parseInt(ln.paid_amount);
        });

        setComponentState({
            score,
            unpaid: total_unpaid,
            paid: total_paid
        });

    }, [])

    return (
        <div className="w-100 px-4 slide_top">
            <div className="w-100 d-flex justify-content-center mt-2 mb-2">
                <div className="rounded-circle" style={{width: 100, height: 100, overflow: "hidden"}}>
                    <img
                        src="/images/user_icon_image_no_bg.png"
                        alt="e-bidir logo"
                        width="100"
                        style={{position: "relative", top: "50%", transform: "translateY(-50%)"}}
                    />
                </div>
            </div>
            <h5 className="text-center h-50 mb-0">{loggedUser.FullName}</h5>
            <div className="text-center fs-5 lead">{loggedUser.Roles[0]}</div>
            <div className="text-center fs-5 lead">{loggedUser.Phone}</div>
            <div className="text-center mb-3 fs-5 lead">{loggedUser.Email}</div>

            <div className="card sahdow rounded-4 mb-4 text-white" style={{background: "#293759"}}>
                <div className="px-2 py-3  d-flex">
                    <div className="w-100">
                        <h5 className="card-title text-center text-success">Paid</h5>
                        <div className="fs-6 text-center">{Utils.moneyDisplay(componentState.paid)}ETB</div>
                    </div>
                    <div style={{width: "3px"}} className="bg-warning mx-1"></div>
                    <div className="w-100">
                        <h5 className="card-title text-center">Score</h5>
                        <div className="fs-5 text-center">{componentState.score}</div>
                    </div>
                    <div style={{width: "3px"}} className="bg-warning mx-1"></div>
                    <div className="w-100">
                        <h5 className="card-title text-center text-danger">Unpaid</h5>
                        <div className="fs-6 text-center">{Utils.moneyDisplay(componentState.unpaid)}ETB</div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <button className="w-100 py-2 bg-white fs-4 custom-shadow custom-input" onClick={() => {navigate("/info")}}>{loggedUser.Roles.includes(UserRoles.COMPANY) ? "Company" : "Personal"} Info</button>
            </div>
            <div className="mb-3">
                <button className="w-100 py-2 bg-white fs-4 custom-shadow custom-input" onClick={() => {navigate("/economic_info")}}>{loggedUser.Roles.includes(UserRoles.COMPANY) ? "Company" : "Personal"} Economic</button>
            </div>
            <div className="mb-3">
                <button className="w-100 py-2 bg-white fs-4 custom-shadow custom-input" onClick={() => {navigate("/business_plan_list")}} > Business Plan</button>
            </div>

            <div className="mb-3">
                <button className="w-100 py-2 bg-white fs-4 custom-shadow custom-input" onClick={() => {navigate("/loans_list")}}>Requested Loans</button>
            </div>
        </div>

    )
}

export default Profile;